import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  userButton: {
    backgroundColor: "white",
    height: '70%',
    // width: "max-content",
    [theme.breakpoints.down("sm")]: {
      textAlign: "-webkit-left",
      height: '66vh',
    },
    "& Button": {
      width: "100%",
      marginTop: "5px",
      display: "flex",
      height: 38,
      color: "white",
      [theme.breakpoints.down("sm")]: {
        marginTop: 5,
      },
      "& .MuiButton-label": {
        gap: "20px",
        alignItems: "center",
        // justifyContent: "flex-start",
      },
      '&:hover': {
        background: 'none',
      },
    },
    "& span": {
      textTransform: 'capitalize',
      height: 35,
      color: 'black'
    },
    "& svg": {
      width: 24,
      height: 24
    }
  },
  eyeIcon: {
    color: "black",
    height: '52px',
    marginRight: '12px',
    marginLeft: '-37px'
  },
  buttonContained: {
    "& Button": {
      color: "white",
      backgroundColor: "#0586ee",
    },
  },
  userTypography: {
    "& .MuiTypography-root": {
      marginTop: "20px",
      fontSize: "1.4em",
    },
  },
  profile: {
    "& .MuiAvatar-circular": {
      width: "100px",
      height: "100px",
    },
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 330,
    backgroundColor: "#0386ee",
    boxShadow: 24,
    borderRadius: 10,
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
    "& .MuiInputBase-input": {
      color: "#000 !important",
    },
    "& Input": {
      // display: "none",
    },
    "& .css-i4bv87-MuiSvgIcon-root": {
      color: "#fff",
    },
    "& img": {
      marginTop: "20px",
      borderRadius: "50%",
    },
  },
  modalChangeName: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 330,
    backgroundColor: "#0386ee",
    boxShadow: 24,
    borderRadius: 10,
    textAlign: "center",
    "& .MuiInputBase-input": {
      color: "#fff !important",
    },
    "& Input": {
      // display: "none",
    },
    "& .css-i4bv87-MuiSvgIcon-root": {
      color: "#fff",
    },
    "& img": {
      marginTop: "20px",
      borderRadius: "50%",
    },
  },
  changePassword: {
    color: "#fff",
    padding: "15px 0px 0px 0px",
    backgroundColor: "#060543",
    textAlign: "center",
    "& .MuiFormControl-root": {
      padding: 10,
    },
    "& p": {
      textAlign: "center",
      color: "white",
      paddingBottom: 19,
    },
  },
  modalHeader: {
    fontSize: "18px",
    padding: "10px 0px 10px 0px",
    color: "white",
    textAlign: "center",
  },
  modalFooter: {
    // display: "flex",
    // flexDirection: "row-reverse",
    backgroundColor: "white",
    // padding: "19px",
    borderRadius: "0px 0px 10px 10px",
  },
  modalFooter1: {
    // display: "flex",
    // flexDirection: "row-reverse",
    // backgroundColor: "white",
    // padding: "10px",
    // borderRadius: "0px 0px 10px 10px",
  },
  modalBody: {
    margin: "0px",
    padding: "0px",
    fontSize: "15px",
    // padding: "10px",
    color: "white",
    textAlign: "center",
    background: "white",
    "& .MuiInputBase-root": {
      paddingLeft: "10px",
    },
  },
  modalBod: {
    fontSize: "15px",
    padding: "10px",
    color: "white !important",
    textAlign: "center",
    background: "#060543"
  },
  userProfile: {
    "& .css-ci5apu-MuiSvgIcon-root": {
      // color: "#fff",
    },
  },
  userName: {
    display: "block",
    textAlign: "center",
    color: "#fff",
 
  },
  rootLogo: {
    textAlign: "center",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: '#060543',
    // marginTop: 10,
    "& img": {
      width: 128,
      paddingTop: 20,
      paddingBottom: 20,
      "&:hover": {
        cursor: "pointer",
      },
    },
    "& span": {
      "& svg": {
        // width: "2em",
        height: "2em",
        color: 'white'
      },
      position: "absolute",
      top: "50%",
      left: 20,
      transform: "translateY(-50%) translateX(-50%)",
    },
  },
  editProfile: {
    // backgroundColor: "#060543",
    // color: "white",
    height: '100%',
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& span": {
      color: "black",
    },
    "& Input": {
      display: "none",
    },
    "& .css-i4bv87-MuiSvgIcon-root": {
      color: "#fff",
    },
    // "& img": {
    //   width: 100,
    //   paddingTop: 20,
    //   paddingBottom: 20,
    //   borderRadius: '50%'
    // },
  },
  upperProfile: {
    backgroundColor: "#060543",
    color: "white",
    height: 179,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& span": {
      color: "red",
    },
    "& Input": {
      display: "none",
    },
    "& .css-i4bv87-MuiSvgIcon-root": {
      color: "#fff",
    },
    "& img": {
      width: 80,
      backgroundColor: "#060543",
      // border: '1px solid #060543',
      // height: '100vh',
      paddingTop: 12,
      paddingBottom: 12,
      borderRadius: '50%'
    },
  },
  main: {
    justifyContent: "center",
    "& .MuiCircularProgress-root": {
      top: "50%",
      position: "absolute",
    },
  },
  uploadBtn: {
    "& button": {
      background: "#060543",
      color: "#fff"
    },
    "&:hover": {
      "& button": {
        background: "#fff",
        color: "#060543"
      },
    },
  },
  cancelBtn: {
    "& button": {
      background: "red",
      color: "#fff"
    },
    "&:hover": {
      "& button": {
        background: "#fff",
        color: "red"
      },
    }
  },
}));
export default useStyles;
