import { makeStyles } from "@material-ui/core/styles";
import { colorStyles } from "../../assests/css/color";

const useStyles = makeStyles((theme) => ({
  PageSectionHeading: {
    position: "absolute",
    left: 0,
    color: "white",
    alignSelf: "flex-end",
    margin: "2px",
    display: "none",
    fontSize: "32px",
    fontWeight: "bolder",
    [theme.breakpoints.down("xs")]: {
      display: "block",
      marginLeft: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      display: "block",
      marginLeft: "10px",
      // backgroundColor: theme.palette.primary.main,
    },
  },
  root: {
    position: "relative",
    height: "100vh",
  },
  signInContainer: {
    // width: "100%",
    right: "10%",
    position: "absolute",
    display: "flex",
    height: "100%",
    [theme.breakpoints.down("xs")]: {
      width: "90%",

      position: "static",
    },
  },
  signInHeader: {
    color: "#FFFFFF",
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 10,
    [theme.breakpoints.down("xs")]: {
      marginBottom: 40,
    },
  },
  signIn: {
    display: "flex",
    flexDirection: "column",
    padding: "20px 0",
    width: "100%",
    maxWidth: 350,
    margin: "auto",
    padding: "15px",
    border: "1px solid #ececec",
    // background: "transparent",
    // background: "rgba(255, 255, 255, 0.5)",
    background: "white",
    backdropFilter: "blur(10px)",
    borderRadius: 20,
    "& .MuiInputBase-root": {
      padding: "14px 10px",
      "& > div": {
        flex: "10%",
      },
      "& > input": {
        padding: "0 0 0 10px",
        color: "#000",
        fontSize: 18,
        "&::placeholder": {
          color: "#000",
          fontSize: 18,
        },
        "&:-webkit-autofill": {
          "-webkit-background-clip": "text",
        },
      },
      "&:before": {
        borderBottom: "1px solid #E8E8E8",
      },
    },
    "& svg": {
      fill: "#2E8BF7",
    },
    "& .MuiFormHelperText-root": {
      // paddingLeft: 25,
    },
    "& .MuiBox-root": {
      "& > p": {
        fontSize: 14,
        display: "flex",
        justifyContent: "flex-end",
        // color: "#3F83F8",
        // marginTop: 10,
        // [theme.breakpoints.down("xs")]: {
        //   marginTop: 0,
        // },
      },
    },
    "& .MuiTextField-root": {
      // marginBottom: "10px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  hover: {
    color: colorStyles.secondaryColor,
    "&:hover": {
      cursor: "pointer",
    },
  },
  signInFooter: {
    color: "#FFFFFF",
    fontSize: 14,
    marginTop: 20,
    "& span": {
      fontWeight: 600,
      "&:hover": {
        cursor: "pointer",
      },
    },
  },

  signUpFooter: {
    color: "black",
    fontSize: 14,
    marginTop: 20,
    "& span": {
      fontWeight: 600,
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
  signUpSpan: {
    fontSize: 14,
    color: colorStyles.secondaryColor,
  },

  ImagebackgroundColor: {
    display: "flex",
    backgroundColor: colorStyles.secondaryColor,
    // "& svg": {
    //   fill: "red",
    // },
  },
  containerHeight: {
    height: "100vh",
    justifyContent: "center",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      "&:before": {
        content: "''",
        width: "100%",
        height: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        background:
          "linear-gradient(169deg, rgba(67, 67, 67, 0.56) 0.67%, rgba(58, 58, 58, 0.32) 102.5%)",
        Filter: "blur(10px)",
      },
    },

    "& > img": {
      position: "absolute",
      bottom: "0",
      left: "0",
      maxWidth: "100%",
      height: 400,
      [theme.breakpoints.down("sm")]: {
        height: "auto",
        zIndex: -1,
      },
    },
  },
  imageStyle: {
    // padding: "20px",
    width: "100%",
    backgroundColor: colorStyles.secondaryColor,
    maxHeight: "100%",
  },
  HeadingText: {
    color: "#060543",
    fontSize: "20px",
    // fonttyle: normal;
    fontWeight: "700",
    // line-height: normal;
    // fontSize: "36px",
    marginBottom: "25px",
    // display: "block",

    // [theme.breakpoints.down("xs")]: {
    //   display: "none",
    // },
    // [theme.breakpoints.down("sm")]: {
    //   display: "none",
    //   // backgroundColor: theme.palette.primary.main,
    // },
  },
  BottomTextPosition: {
    display: "flex",
    justifyContent: "center",
    // justifyContent: "space-between",
    marginTop: "5px",
    // [theme.breakpoints.down("xs")]: {
    //   display: "inline-block",
    // },
  },
  verifyOTP: {
    "& .MuiTypography-alignCenter": {
      textAlign: "start",
    },

    // width: "max-content",
    // padding: "30px 20px",
    background: "#FFFFFF",
    borderRadius: 20,
    margin: "auto",
    "& .title": {
      fontSize: 18,
      marginBottom: 30,
      fontWeight: "bolder",
    },
    "& .subTitle": {
      width: "75%",
      // margin: "auto",
    },
    "& .otpContainer": {
      margin: "auto",
      padding: "25px 0",
      position: "relative",
      "& > div": {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        margin: "auto",
      },
      "& > p": {
        color: "#d32f2f",
        margin: 0,
        bottom: 0,
        position: "absolute",
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        fontWeight: "400",
        fontSize: "0.75rem",
        left: "10%",
      },
    },
    "& .otpInput": {
      width: "35px !important",
      height: 38,
      background: "#F5F5F5",
      border: "1px solid #2B8CF7",
      borderRadius: 0,
      "&:focus-visible": {
        outline: "2px solid #2B8CF7",
      },
    },
    "& .otpErrorState": {
      border: "1px solid red",
      "&:focus-visible": {
        border: "none",
      },
    },
    "& .actionButtons": {
      display: "flex",
      flexDirection: "column",
      gap: 20,
      [theme.breakpoints.down("xs")]: {
        gap: 0,
      },
    },
    [theme.breakpoints.down("xs")]: {
      minHeight: "70%",
      // position: "absolute",
      width: "100%",
      borderTopLeftRadius: "20px",
      borderTopRightRadius: "20px",
      borderBottomLeftRadius: "0",
      borderBottomRightRadius: "0",
      // bottom: "0",
    },
    "& button": {
      width: "70%",
    },
  },
  verificationContainer: {
    display: "flex",
    flexDirection: "column",
    // alignItems: "center",
  },
  verificationHeader: {
    display: "flex",
    flexDirection: "column",
    // alignItems: "center",
    marginTop: 5,
    "& > p": {
      color: "black",
      fontSize: 18,
      fontWeight: 600,
    },
    "& .forgotPasswordTitle": {
      fontSize: 18,
      color: "black",
      fontWeight: 400,
    },
    // "& > svg": {
    // 	scale: "0.5",
    // },
  },
}));
export default useStyles;
