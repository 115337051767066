import * as React from "react";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Typography,
  TextField,
  Modal,
  CircularProgress,
  Divider,
} from "@material-ui/core";
import { getUserInfo } from "../services/getUserInfo";
import { Auth } from "aws-amplify";
import useStyles from "../assests/css/profileStyle";
import "../assests/css/style.css";
import { useHistory } from "react-router-dom";
import { CheckIfPasswordIsValid, getBase64 } from "../services/utils";
import { getUserImage, uploadImageName } from "../api";
import { CModal, CroppieModal } from ".";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const Profile = (props) => {
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [openChangePassword, setOpenChangePassword] = React.useState(false);
  const handleClose = () => setOpen(false);

  const handleCloseChangePassword = () => {
    setOpenChangePassword(false);
    setShowError(false);
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
  };
  const handleOpenChangePassword = () => setOpenChangePassword(true);
  const handleOpen = () => setOpen(true);
  const { bottomTabValueHandler } = props;
  const classes = useStyles();
  const [userDetails, setUserDetails] = React.useState({});
  const name = userDetails.email && userDetails.email.split("@")[0];
  const [userName, setUserName] = React.useState("");
  const [openName, setOpenName] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [userNameNew, setUserNameNew] = React.useState("");
  const [showError, setShowError] = React.useState(false);
  const [showImageError, setImageShowError] = React.useState(false);
  const [croppedImage, setCroppedImage] = React.useState("");
  const handleCloseName = () => setOpenName(false);
  const [img, setImg] = React.useState(localStorage.getItem("img"));
  const [oldPassword, setOldPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [imageUpload, setImageUpload] = React.useState(false);
  const [selectedImg, setSelectedImg] = React.useState(null);
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [oldPasswordShow, setOldPasswordShow] = React.useState(false);
  const [newPasswordShow, setNewPasswordShow] = React.useState(false);
  const [confirmPasswordShow, setConfirmPasswordShow] = React.useState(false);
  // const [newState, setNewState] = useState("");
  // const id = btoa(name && name.replace(".", ""));

  const handleCloseImageUpload = () => {
    setImageUpload(false);
  };

  React.useEffect(() => {
    getUserDetails();
  }, []);

  React.useEffect(() => {
    getUserData();
  }, []);
  React.useEffect(() => {
    localStorage.setItem("BottomNavigationScreenIndex", 2);

    bottomTabValueHandler(2);
  }, []);
  React.useEffect(() => {
    return () => {
      localStorage.setItem("BottomNavigationScreenIndex", 2);
    };
  });

  React.useEffect(() => {
    if (name) {
      // console.log("insidegetUserEmailUserName");
      getUserEmailUserName();
    }
  }, [name]);

  React.useEffect(() => {
    // console.log("ghkgfkgfkf");
    let imgimg = localStorage.getItem("img");
    // console.log("imgimg  ", imgimg);
    if (imgimg) {
      setImg(imgimg);
      // console.log("imgimgimgimg", imgimg);
      // console.log("ifif");
    } else {
      if (name) {
        // console.log("ifelseelse");
        getUserEmailUserName();
      }
      // console.log("elseelse");
    }
  }, [name]);

  React.useEffect(() => {
    // console.log("imgLOLO", img);
  }, [img]);

  const getUserDetails = () => {
    const details = getUserInfo();
    setUserDetails(details);
  };

  const getUserData = async () => {
    const details = await getUserInfo();
    setUserDetails(details);
  };

  const signOutBtn = () => {
    bottomTabValueHandler(0);
    Auth.signOut();
  };

  const changeUserName = () => {
    setUserName(userNameNew);
    const imageBase64 =
      localStorage.getItem("img") && localStorage.getItem("img").split(",")[1];
    saveImageAndUserName(userNameNew, imageBase64);
    handleCloseName();
  };

  const saveImageAndUserName = async (updateName, image) => {
    const userName = name + "_" + updateName;

    const out = await uploadImageName(userName, image);
  };

  const getUserEmailUserName = async () => {
    setIsLoading(true);
    // console.log("name", name);
    const imageData = await getUserImage(name);

    setImg(imageData.files.link);
    const data = imageData.files.link;
    setUserName(
      data
        .substr(data.lastIndexOf("/") + 1)
        .split(".")[0]
        .split("_")[1]
    );
    // console.log("imageData.files.link", imageData.files.link);
    if (imageData.files.link) {
      setIsLoading(false);
    }
  };

  const handleChangePassword = () => {
    const isValid = CheckIfPasswordIsValid(
      oldPassword,
      newPassword,
      confirmPassword
    );
    if (isValid) return setShowError(isValid);
    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(user, oldPassword, newPassword);
      })
      .then((data) => {
        if (data === "SUCCESS") {
          signOutBtn();
          history.push("/");
          setOldPassword("");
          setNewPassword("");
          setConfirmPassword("");
          handleCloseChangePassword();
        } else {
          setShowError("Old password not matched!");
        }
      })
      .catch((err) => setShowError("Old password not matched!"));
  };

  const handleChange = (e, type) => {
    if (type === "old") setOldPassword(e.target.value);
    if (type === "new") setNewPassword(e.target.value);

    if (type === "confirm") setConfirmPassword(e.target.value);
    setShowError(false);
  };

  const uploadImage = () => {
    if (selectedImg.size > 2500000) {
      handleCloseImageUpload();
      return setImageShowError("Image should be only max 2MB");
    }
    setImageShowError(false);
    getBase64(selectedImg).then((base64) => {
      localStorage["img"] = base64;
      // console.log("base64", base64);
      setImg(base64);
      // console.log("base64.split(", ")[1]", base64);
      localStorage.setItem("img", base64);
      saveImageAndUserName(userName, base64.split(",")[1]);
      handleCloseImageUpload();
    });
  };

  // const handleClickShowPassword = () => setOldPasswordShow(!oldPasswordShow);
  // const handleClickShowNewPassword = () => setNewPasswordShow(!newPasswordShow);
  // const handleClickShowConfirmPassword = () =>
  //   setConfirmPasswordShow(!confirmPasswordShow);

  return (
    <div>
      <Grid container className={classes.main}>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={classes.profile}
          >
            <Box className={classes.upperProfile}>
              <img
                src={img}
                key={img}
                name="file"
                className="img-thumbnail"
                height={100}
                width={100}
                alt="profile"
              />

              {showImageError && <span>{showImageError}</span>}
              <CModal
                maxWidth="xs"
                open={imageUpload}
                handleClose={handleCloseImageUpload}
                bgClick={true}
              >
                <CroppieModal
                  image={selectedImg}
                  uploadImage={uploadImage}
                  uploading={false}
                  closeModal={handleCloseImageUpload}
                  modalTitle={"Change profile picture"}
                  error={showImageError}
                  setImage={setCroppedImage}
                />
                <Grid container>
                  <Grid item xs={6} align={"left"}>
                    <div className={classes.cancelBtn}>
                      <Button
                        variant="outlined"
                        onClick={handleCloseImageUpload}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Grid>
                  <Grid item xs={6} align={"right"}>
                    <div className={classes.uploadBtn}>
                      <Button
                        variant="outlined"
                        disabled={!selectedImg && !showImageError}
                        onClick={() => uploadImage(croppedImage)}
                      >
                        Upload
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </CModal>
              <Box className={classes.userProfile}>
                <div>
                  <Typography
                    className={classes.userName}
                    gutterBottom
                    variant="h5"
                    component="div"
                  >
                    {userName}
                  </Typography>
                </div>
                <p style={{ marginTop: "2px" }}>{userDetails.email}</p>
              </Box>
            </Box>
            <Box className={classes.userButton}>
              <div style={{ paddingTop: "13px" }}>
                <Button onClick={() => history.push(`/edit-profile`)}>
                  Update Profile
                </Button>
                {/* <Divider /> */}
                <Button onClick={() => history.push(`/setting`)}>
                  Manage Queue
                </Button>
                {/* <Divider /> */}
                <Button
                  // size="large"
                  onClick={() => history.push(`/custom-form`)}
                  // startIcon={<SettingsIcon />}
                >
                  Custom Form
                </Button>
                {/* <Divider /> */}
                <Button
                  // size="large"
                  onClick={handleOpenChangePassword}
                  // startIcon={<ReactSVG src={lock} />}
                >
                  Change Password
                </Button>
                {/* <Divider /> */}
                <Button onClick={() => history.push(`/voice-message`)}>
                  Send Emergency Message
                </Button>
                {/* <Divider /> */}
                <Button
                  // size="large"
                  onClick={() => history.push(`/device-setting`)}
                  // startIcon={<ReactSVG src={wifiline} />}
                >
                  Wifi Settings
                </Button>
                {/* <Divider /> */}
                <Button
                  // size="large"
                  onClick={() => history.push(`/bill-board`)}
                  // startIcon={<MonitorIcon />}
                >
                  Bill Board
                </Button>
                {/* <Divider /> */}
                <Button
                  // size="large"
                  // startIcon={<ReactSVG src={logoutline} />}
                  onClick={handleOpen}
                >
                  Log Out
                </Button>
              </div>
              {/* Modal for logout confirmation */}
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box className={classes.modal + " " + classes.changePassword}>
                  <Typography
                    id="modal-modal-description"
                    className={classes.modalBod}
                  >
                    Are you sure you want to logout ?
                  </Typography>
                  <Box className={classes.modalFooter}>
                    <Button
                      variant="text"
                      size="medium"
                      onClick={handleClose}
                      style={{
                        backgroundColor: "red",
                        margin: "13px",
                        color: "white",
                      }}
                    >
                      No
                    </Button>
                    <Button
                      variant="text"
                      size="medium"
                      onClick={signOutBtn}
                      style={{
                        backgroundColor: "#060543",

                        margin: "13px",
                        color: "white",
                      }}
                    >
                      Yes
                    </Button>
                  </Box>
                </Box>
              </Modal>
              {/* Modal for changing name */}
              <Modal
                open={openName}
                onClose={handleCloseName}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box className={classes.modal + " " + classes.changePassword}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    className={classes.modalHeader}
                  >
                    Enter new name
                  </Typography>
                  <Box className={classes.modalFooter}>
                    <div style={{ flexDirection: "row", display: "flex" }}>
                      <TextField
                        fullWidth
                        required
                        value={userNameNew}
                        onChange={(e) => {
                          setUserNameNew(e.target.value);
                        }}
                      />
                    </div>
                    <div className={classes.modalFooter1}>
                      <Button
                        variant="text"
                        size="medium"
                        onClick={handleCloseName}
                      >
                        cancel
                      </Button>
                      <Button
                        variant="text"
                        size="medium"
                        onClick={changeUserName}
                        disabled={!userNameNew}
                      >
                        confirm
                      </Button>
                    </div>
                  </Box>
                </Box>
              </Modal>
              {/* Modal for changing password */}
              <Modal
                open={openChangePassword}
                onClose={handleCloseChangePassword}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box className={classes.modal + " " + classes.changePassword}>
                  <Typography
                    id="modal-modal-description"
                    style={{ fontSize: 18 }}
                  >
                    Change Login Password
                  </Typography>
                  <Box className={classes.modalFooter}>
                    <div style={{ flexDirection: "row", display: "flex" }}>
                      <TextField
                        fullWidth
                        placeholder={"Old Password "}
                        autoComplete={false}
                        required
                        type={oldPasswordShow ? "text" : "password"}
                        value={oldPassword}
                        onChange={(e) => handleChange(e, "old")}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {oldPasswordShow ? (
                                <Visibility
                                  style={{ color: "black" }}
                                  onClick={() =>
                                    setOldPasswordShow(!oldPasswordShow)
                                  }
                                />
                              ) : (
                                <VisibilityOff
                                  style={{ color: "black" }}
                                  onClick={() =>
                                    setOldPasswordShow(!oldPasswordShow)
                                  }
                                />
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <div style={{ flexDirection: "row", display: "flex" }}>
                      <TextField
                        fullWidth
                        placeholder={"New Password "}
                        type={newPasswordShow ? "text" : "password"}
                        autoComplete={false}
                        required
                        value={newPassword}
                        onChange={(e) => handleChange(e, "new")}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {newPasswordShow ? (
                                <Visibility
                                  style={{ color: "black" }}
                                  onClick={() =>
                                    setNewPasswordShow(!newPasswordShow)
                                  }
                                />
                              ) : (
                                <VisibilityOff
                                  style={{ color: "black" }}
                                  onClick={() =>
                                    setNewPasswordShow(!newPasswordShow)
                                  }
                                />
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <div style={{ flexDirection: "row", display: "flex" }}>
                      <TextField
                        fullWidth
                        placeholder={"Confirm Password "}
                        type={confirmPasswordShow ? "text" : "password"}
                        autoComplete={false}
                        required
                        value={confirmPassword}
                        onChange={(e) => handleChange(e, "confirm")}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {confirmPasswordShow ? (
                                <Visibility
                                  style={{ color: "black" }}
                                  onClick={() =>
                                    setConfirmPasswordShow(!confirmPasswordShow)
                                  }
                                />
                              ) : (
                                <VisibilityOff
                                  style={{ color: "black" }}
                                  onClick={() =>
                                    setConfirmPasswordShow(!confirmPasswordShow)
                                  }
                                />
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    {showError && (
                      <span style={{ color: "red" }}>{showError}</span>
                    )}
                    <div className={classes.modalFooter1}>
                      <Button
                        variant="text"
                        size="medium"
                        onClick={handleCloseChangePassword}
                        style={{
                          backgroundColor: "red",
                          margin: "13px",
                          color: "white",
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="text"
                        size="medium"
                        onClick={handleChangePassword}
                        style={{
                          backgroundColor: "#060543",
                          margin: "13px",
                          color: "white",
                        }}
                      >
                        Save
                      </Button>
                    </div>
                  </Box>
                </Box>
              </Modal>
              {/* <CModal maxWidth='xs' open={imageUpload} handleClose={handleCloseImageUpload} bgClick={true}>
                <CroppieModal
                  image={selectedImg}
                  uploadImage={uploadImage}
                  uploading={false}
                  closeModal={handleCloseImageUpload}
                  modalTitle={'Change profile picture'}
                />
              </CModal> */}
            </Box>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default Profile;
