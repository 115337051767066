import React, { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField } from "@mui/material";
import dayjs from "dayjs";

export function CTimePicker({ time, handleValueChange }) {
  // console.log("yimr",time);
  const [open, setOpen] = useState(false);
  const onKeyDown = (e) => {
    e.preventDefault();
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopTimePicker
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        value={time}
        onChange={handleValueChange}
        InputProps={{
          disableUnderline: true,
        }}
        renderInput={(params) => (
          <TextField
            onClick={(e) => setOpen(true)}
            variant="standard"
            {...params}
            error
            onKeyDown={onKeyDown}
            helperText={
              !dayjs(time).isValid() ? "Please enter a valid Time" : " "
            }
          />
        )}
      />
    </LocalizationProvider>
  );
}
