import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { colorStyles } from "../assests/css/color";
import { CircularProgress } from "@material-ui/core";
import useStyles from "../assests/css/styles";
export function Footer(props) {
  const classes = useStyles();
  const [img, setImg] = React.useState(localStorage.getItem("img"));
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = "https://www.habilelabs.io/contact-us";
  // React.useEffect(() => {s
  //   setImg(localStorage.getItem("img"));
  // }, [img]);
  // console.log("stylefromfooter", props);

  // console.log("img", img)
  const finalimg = localStorage.getItem("img");
  // const handleNavigation = () => {
  //   window.location.href = "https://www.habilelabs.io/contact-us";
  // };
  React.useEffect(() => {
    if (finalimg) {
      setImg(localStorage.getItem("img"));
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [finalimg]);
  return (
    <div className={classes.header}>
      <span>
        <a href={navigate} className={classes.styledLink} target="_blank">
          Powered by HabileLabs
        </a>
      </span>
    </div>
  );
}
