// import React from "react";
// // import useStyles from "../assests/css/styles";
// import { isEqual } from "lodash";
// import Container from "react-bootstrap/Container";
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
// import Table from "react-bootstrap/Table";
// import moment from "moment";
// import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
// import { Divider } from "@material-ui/core";
// import Radio from "@material-ui/core/Radio";
// import RadioGroup from "@material-ui/core/RadioGroup";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import FormControl from "@material-ui/core/FormControl";
// import FormLabel from "@material-ui/core/FormLabel";
// import Checkbox from "@material-ui/core/Checkbox";
// import { IsVisitedApiHandler, removeMeWaitlistQueue } from "../api";
// import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
// import DeleteIcon from "@mui/icons-material/Delete";
// // import { DataGridPro, RowReordering } from "@material-ui/data-grid";
// import { DataGridPro } from '@mui/x-data-grid-pro';

// import { makeStyles } from "@material-ui/core";
// const useStyles = makeStyles({
//   table: {
//     minWidth: 800,
//   },
//   container: {
//     spacing: 2,
//   },
//   headerCell: {
//     backgroundColor: "rgba(221, 221, 221, 0.5)",
//     fontWeight: "bold",
//     fontSize: 16,
//     padding: 10,
//   },
//   bodyCell: {
//     fontSize: 14,
//     padding: 10,
//   },
//   visits: {
//     backgroundColor: "rgba(221, 221, 221, 0.5)",
//     fontWeight: "bold",
//     fontSize: 16,
//     padding: 10,
//   },
// });
// const TABEL_HEADING = [
//   {
//     id: "visited",
//     label: "Visited",
//   },
//   {
//     id: "name",
//     label: "Name",
//   },
//   {
//     id: "contactno",
//     label: "Contact No.",
//   },
//   {
//     id: "queueno",
//     label: "Queue No.",
//   },
//   {
//     id: "arrivalat",
//     label: "Appointment",
//   },
//   {
//     id: "empty",
//     label: "",
//   },
// ];
// const AnalyticsItem = ({ data, currentNumber }) => {
//   const classes = useStyles();

//   const [expandedRows, setExpandedRows] = React.useState([]);
//   const [selectedRow, setSelectedRow] = React.useState([]);

//   const handleExpandRow = (event, userId) => {
//     setExpandedRows(userId);
//   };

//   const handleSelectedState = (data) => {
//     setSelectedRow((oldArray) => [...oldArray, data]);
//   };

//   const handleIsVisited = (event, data) => {
//     // console.log("deleteHandler", event.target.checked);
//     // console.log("deleteHandler", data);
//     let apiData = {
//       Device_Name: { DeviceName },
//       Date_Time: data,
//       isVisited: event.target.checked,
//     };
//     IsVisitedApiHandler(apiData);

//     if (event.target.checked === true) {
//       setselectedRow((oldArray) => [...oldArray, data]);
//     } else {
//       setselectedRow(selectedRow.filter((item) => item !== data));
//     }
//     setisVisited(event.target.checked);
//   };
//   const deleteHandler = (data) => {
//     console.log("GSI1SK", data);
//     const apidata = {
//       key: data,
//     };
//     console.log("removeuserRegistrationDateTimeIf", apidata);
//     removeMeWaitlistQueue(apidata)
//       .then((res) => {
//         if (res.status === 200) {
//           console.log("deleteHandleruserRemoved");
//         }
//       })
//       .catch((err) => console.log(err));
//   };

//   return (
//     <div className={classes.container}>
//       <DataGridPro
//         rows={data}
//         columns={[
//           {
//             name: "visited",
//             label: "Visited",
//             style: {
//               backgroundColor: "rgba(221, 221, 221, 0.5)",
//               fontWeight: "bold",
//               fontSize: 16,
//               padding: 10,
//             },
//           },
//           {
//             name: "name",
//             label: "Name",
//             style: {
//               fontSize: 14,
//               padding: 10,
//             },
//           },
//           {
//             name: "contactno",
//             label: "Contact No.",
//             style: {
//               fontSize: 14,
//               padding: 10,
//             },
//           },
//           {
//             name: "queueno",
//             label: "Queue No.",
//             style: {
//               fontSize: 14,
//               padding: 10,
//             },
//           },
//           {
//             name: "arrivalat",
//             label: "Appointment",
//             style: {
//               fontSize: 14,
//               padding: 10,
//             },
//           },
//           {
//             name: "empty",
//             label: "",
//             style: {
//               fontSize: 14,
//               padding: 10,
//             },
//           },
//         ]}
//         rowReordering
//         onRowReorderingChange={(event, row) => {
//           const newExpandedRows = [...expandedRows];
//           const index = newExpandedRows.indexOf(row.id);
//           if (index !== -1) {
//             newExpandedRows.splice(index, 1);
//           }
//           setExpandedRows(newExpandedRows);
//         }}
//       />
//     </div>
//   );
// };

// export default React.memo(AnalyticsItem, (prev, next) => isEqual(prev, next));



import React from "react";
import useStyles from "../assests/css/styles";
import { isEqual } from "lodash";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import moment from "moment";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Divider } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { IsVisitedApiHandler, removeMeWaitlistQueue } from "../api";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DeleteIcon from "@mui/icons-material/Delete";
const TABEL_HEADING = [
  {
    id: "visited",
    label: "Visited",
  },
  {
    id: "name",
    label: "Name",
  },
  {
    id: "contactno",
    label: "Contact No.",
  },
  {
    id: "queueno",
    label: "Queue No.",
  },
  {
    id: "arrivalat",
    label: "Appointment",
  },
  {
    id: "empty",
    label: "",
  },
];

const AnalyticsItem = ({ data, currentNumber }) => {
  console.log("TABLE_____");

  let DeviceName;
  if (data.length > 0) {
    DeviceName = data[0].Device_Name;
  }
  const [expandedRows, setExpandedRows] = React.useState([]);
  const classes = useStyles();
  const [expandState, setExpandState] = React.useState({});
  const [isVisited, setisVisited] = React.useState(false);
  const [selectedRow, setselectedRow] = React.useState([]);
  const handleEpandRow = (event, userId) => {
    setExpandedRows(userId);

    // const currentExpandedRows = expandedRows;
    // const isRowExpanded = currentExpandedRows.includes(userId);

    // let obj = {};
    // isRowExpanded ? (obj[userId] = false) : (obj[userId] = true);
    // setExpandState(obj);

    // const newExpandedRows = isRowExpanded
    //   ? currentExpandedRows.filter((id) => id !== userId)
    //   : currentExpandedRows.concat(userId);

    // setExpandedRows(newExpandedRows);
  };
  React.useEffect(() => {
    data.map((user) => {
      if (user.Is_Visited === "1") {
        setselectedRow((oldArray) => [...oldArray, user.Date_Time]);
      }
    });
  }, [data]);
const handleIsVisited = (event, data) => {
  // console.log("deleteHandler", event.target.checked);
  // console.log("deleteHandler", data);
  let apiData = {
    Device_Name: { DeviceName },
    Date_Time: data,
    isVisited: event.target.checked,
  };
  IsVisitedApiHandler(apiData);

  if (event.target.checked === true) {
    setselectedRow((oldArray) => [...oldArray, data]);
  } else {
    setselectedRow(selectedRow.filter((item) => item !== data));
  }
  setisVisited(event.target.checked);
};
  const handleSelectedState = (data) => {
    // setselectedRow(data)
  };
const deleteHandler = (data) => {
  console.log("GSI1SK",data)
  const apidata = {
    key: data
  };
  console.log("removeuserRegistrationDateTimeIf", apidata);
  removeMeWaitlistQueue(apidata)
    .then((res) => {
      if (res.status === 200) {
        console.log("deleteHandleruserRemoved");
      }
    })
    .catch((err) => console.log(err));
};
  // data.map((user) => {
  //   if (user.Is_Visited === "1") {
  //     setselectedRow((oldArray) => [...oldArray, user.Date_Time]);
  //   }
  // });

  const handleCloseExpandRow = () => {
    // console.log("handleCloseExpandRow");
    setExpandedRows([]);
  };
  // console.log("expandedRows", expandedRows);
  return (
    <Container
      style={{
        textAlign: "center",
        overflow: "auto",
        width: "100%",
        flex: 1,
      }}
    >
      <Row>
        <Col sm={12}>
          <Table
            className={classes.tableModel}
            style={{ width: "100%" }}
          >
            <thead>
              <tr
                className={classes.queueName + " " + classes.headingDark}
                component="div"
              >
                {TABEL_HEADING.map((item) => (
                  <th key={item.id}>{item.label}</th>
                ))}
              </tr>
            </thead>
            {/* <Divider /> */}
            <tbody>
              {/* {console.log("analticdata", data)} */}
              {data &&
                data.map((user) => (
                  <>
                    {console.log("analticdata", user.Date_Time)}

                    <tr
                      key={user.Date_Time}
                      className={
                        selectedRow.indexOf(user.Date_Time) > -1
                          ? classes.queueNameVisited
                          : Number(user.Count) === currentNumber &&
                            Number(user.Count) !== 0
                          ? classes.queueNameCurrent
                          : classes.queueName
                      }
                    >
                      <td>
                        <Checkbox
                          style={{ color: "#0386ee" }}
                          // checked={selectedRow.indexOf(user.Date_Time) > -1 || {user.Is_Visited === 1}}
                          // checked={
                          //   user.Is_Visited === "1" ||
                          //   selectedRow.indexOf(user.Date_Time) > -1
                          // }
                          checked={selectedRow.indexOf(user.Date_Time) > -1}
                          onChange={(event) => {
                            handleIsVisited(event, user.Date_Time);
                            handleSelectedState(user.Date_Time);
                          }}
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </td>
                      <td>{user.Name}</td>
                      <td>
                        {
                          <a
                            href={`tel:+${user.Contact}`}
                          >{`+${user.Contact}`}</a>
                        }
                      </td>
                      <td>{user.Count == 0 ? "NA" : user.Count}</td>
                      <td>
                        {/* {user.Date_Time} */}
                        {moment(user.Date_Time, "YYYY-MM-DD/HH:mm:ss").format(
                          "hh:mm A"
                        )}
                        {/* {moment(new Date(user.Date_Time)).format("hh:mm A")} */}
                      </td>

                      <td style={{ cursor: "pointer" }}>
                        {expandedRows === user.Date_Time ? (
                          <KeyboardArrowUpIcon
                            color="primary"
                            onClick={(event) => handleCloseExpandRow()}
                          />
                        ) : (
                          <ArrowDropDownIcon
                            color="primary"
                            onClick={(event) =>
                              handleEpandRow(event, user.Date_Time)
                            }
                          />
                        )}
                      </td>
                      <td
                        onClick={() => {
                          deleteHandler(user.GSI1SK);
                        }}
                      >
                        <DeleteIcon style={{ color: "red" }} />
                      </td>
                    </tr>
                    <>
                      {expandedRows.includes(user.Date_Time) ? (
                        <tr>
                          <td colspan="6">
                            <div style={{ padding: "10px", textAlign: "left" }}>
                              {/* {console.log("Form_Data", user)} */}
                              {/* {console.log("Form_Data", typeof user.Form_Data)} */}

                              <ul>
                                {user.Form_Data &&
                                  Object.entries(user.Form_Data).map(
                                    ([key, value]) => {
                                      if (key !== "notificationId") {
                                        return (
                                          <li>
                                            <span>
                                              <b>{key.charAt(0).toUpperCase() + key.slice(1)} </b>
                                            </span>
                                            <span>{value}</span>
                                          </li>
                                        );
                                      }
                                    }
                                  )}
                              </ul>
                            </div>
                          </td>
                        </tr>
                      ) : null}
                    </>
                  </>
                ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default React.memo(AnalyticsItem, (prev, next) => isEqual(prev, next));
