import React from "react";
import { Box, IconButton } from "@material-ui/core";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useHistory, useLocation, useParams } from "react-router-dom";

import Demo from ".././Menu/SideNavigation";
import useStyles from "../../assests/css/styles";
import Logo from "../../assests/images/habilelabs_full_logo.svg";
import MenuIcon from "@mui/icons-material/Menu";
export function Header(props) {
  console.log("headerProps", props);
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();

  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };
  React.useEffect(() => {
    // Add event listener to track screen resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const menuCloseHandler = () => {
    props.handleDeviceAdded && props.handleDeviceAdded();
    history.push("/");
  };
  console.log("window.location.href", props.handleDeviceAdded);

  return (
    <div className={classes.headerModel}>
      <div className={classes.headerOption} style={{ color: "#fff" }}>
        <Demo handleDeviceAdded={props.handleDeviceAdded} />
      </div>
      <div className={classes.imageModel}>
        <img
          style={{
            width: "100%",
            maxWidth: "8rem",
            alignItems: "center",
            cursor: "pointer",
          }}
          src={Logo}
          alt="logo"
          onClick={() => menuCloseHandler()}
        />
      </div>
    </div>
  );
}
