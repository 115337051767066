import * as React from "react";
import { styled } from "@mui/material/styles";
import { Grid, Typography, Box, Button, Container } from "@mui/material";
import dummyImage from "../assests/images/404.svg";


const HeadContainer = styled(Grid)(({ theme }) => ({
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
}));
const TextBg = styled(Box)(({ theme }) => ({
    background: '#07174B',
    height: "100%",
    width: "100%",
    overflowY: "hidden",
    position: "absolute",
}));

const TopSection = styled(Grid)(({ theme }) => ({ textAlign: "right" }));
const Page = styled(Grid)(({ theme }) => ({
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    color: '#58abe4',
    padding: theme.spacing(3, 55, 3, 2.5),
    [theme.breakpoints.down("llmd")]: { padding: theme.spacing(3, 40, 3, 2.5) },
    [theme.breakpoints.down("md")]: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        padding: theme.spacing(3, 0, 0, 11),
    },
    [theme.breakpoints.down("sm")]: { padding: theme.spacing(2, 0, 0, 11) },
    "& .MuiTypography-h1": {
        letterSpacing: "4px",
        fontSize: "65px",
        padding: theme.spacing(1.4, 0, 1.4, 3),
        [theme.breakpoints.down("lg")]: { fontSize: "55px" },
        [theme.breakpoints.down("md")]: { padding: theme.spacing(3, 0, 0, 2) },
        [theme.breakpoints.down("sm")]: { padding: theme.spacing(2, 0, 2, 2) },
    },
    "& .MuiTypography-body1": { paddingLeft: "22px !important" },
}));
const Icon = styled(Box)(({ theme }) => ({
    padding: theme.spacing(29.5, 2.5, 29.5, 55),
    [theme.breakpoints.down("llmd")]: {
        padding: theme.spacing(29.5, 2.5, 29.5, 40),
    },
    [theme.breakpoints.down("md")]: {
        padding: theme.spacing(0, 11, 3, 11),
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
    },
}));
const HomeButton = styled(Button)(({ theme }) => ({
    borderRadius: 22,
    height: "40px",
    color: theme.palette.secondary.contrastText,
    padding: theme.spacing(1, 9),
    background: theme.palette.primary.light,
    fontWeight: 600,
    textTransform: "capitalize",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": { backgroundColor: 'white', color: 'black', pointerEvents: 'none'
},
    [theme.breakpoints.down("lg")]: {
        padding: theme.spacing(1, 7),
        height: "38px",
    },
}));
const HomeActionButton = styled(Box)(({ theme }) => ({
    padding: theme.spacing(3.9, 0, 0, 0),
    [theme.breakpoints.down("lg")]: { padding: theme.spacing(3, 0, 0, 0) },
    [theme.breakpoints.down("md")]: { paddingLeft: theme.spacing(1) },
}));

const PageError = (props) => {
    return (
        <TextBg>
            {" "}
            <Container maxWidth="xl">
                {" "}
                <HeadContainer container spacing={2}>
                    {" "}
                    <TopSection item xs={12} md={6}>
                        {" "}
                        <Icon>
                            {" "}
                            <img
                                width="274"
                                height="289"
                                src={dummyImage}
                                alt="Page Error"
                            />{" "}
                        </Icon>{" "}
                    </TopSection>{" "}
                    <Page item xs={12} md={6}>
                        {" "}
                        <Box>
                            {" "}
                            <Typography variant="h1">{props.title}</Typography>{" "}
                            <Typography variant="body1">{props.subtitle}</Typography>{" "}
                            <HomeActionButton>
                                {" "}
                                <a href="/" passHref legacyBehavior>
                                    {" "}
                                    <HomeButton>
                                        {" "}
                                        <Typography variant="button">Home</Typography>{" "}
                                    </HomeButton>{" "}
                                </a>{" "}
                            </HomeActionButton>{" "}
                        </Box>{" "}
                    </Page>{" "}
                </HeadContainer>{" "}
            </Container>{" "}
        </TextBg>
    );
};
export default PageError;
