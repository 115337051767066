import {
  Box,
  InputAdornment,
  Typography,
  Grid,
  TextField,
} from "@mui/material";
import { Auth } from "aws-amplify";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import OtpInput from "react-otp-input";
import useStyles from "../../assests/css/SignInCss";
import { ReactComponent as KeyIcon } from "../../assests/icon/ForgotPassword.svg";
import CAlert from "../CAlert";
import AuthPageImage from "./AuthPageImage";
import GroupIcon from "../../assests/images/Group.png";
import QeaseLogo from "../../assests/images/QeaseLogo.png";
import GroupPeople from "../../assests/images/GroupPeople.png";

import CButton from "./CButton";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const ForgotPasswordSubmit = ({
  formState,
  authState,
  onStateChange,
  updateFormState,
}) => {
  const classes = useStyles();
  const { control, handleSubmit, reset, watch, getValues } = useForm();
  const [error, setError] = useState({ state: false, message: "" });
  const [isDisabled, setIsDisabled] = useState(false);
  const [alert, setAlert] = useState({ message: "", type: "", visible: false });
  const [newPasswordShow, setNewPasswordShow] = React.useState(false);
  const [confirmPasswordShow, setConfirmPasswordShow] = React.useState(false);

  const password = useRef({});
  password.current = watch("password", "");

  useEffect(() => {
    return () => {
      setError({ state: false, message: "" });
      setAlert({ message: "", type: "" });
      reset();
    };
  }, [authState]);
  useEffect(() => {
    if (alert.message !== "") {
      // Set the alert to show
      setAlert({ message: alert.message, visible: true });

      // Schedule a timeout to hide the alert after 5 seconds
      const timeoutId = setTimeout(() => {
        // Hide the alert after the specified duration
        setAlert({ message: "", visible: false });
      }, 5000);

      // Clean up the timeout to avoid memory leaks
      return () => clearTimeout(timeoutId);
    }
  }, [alert.message]);

  const handleOTP = (otp) => {
    updateFormState({ ...formState, authCode: otp });
    setError({ state: false, message: "" });
    // if (error.state) {
    if (otp.length !== 6) {
      setError({
        state: true,
        message:
          otp.length === 0
            ? "OTP cannot be empty"
            : "OTP should contain 6 digits",
      });
    }
  };
  useEffect(() => {
    if (!error.state && error.message === "") {
      setError({ state: false, message: "" });
    }
  }, [formState.authCode]);
  const resendConfirmationCode = async () => {
    updateFormState({ ...formState, authCode: "" });
    setError({ state: false, message: "" });
    setAlert({ message: "", type: "" });
    try {
      let response = await Auth.forgotPassword(formState.email);
      setAlert({
        message: `Verification code sent to ${response.CodeDeliveryDetails.Destination}`,
        type: "success",
      });
    } catch (err) {
      setAlert({ message: error.message, type: "error" });
    }
  };

  const handleClick = () => {
    setAlert({ message: "", type: "" });
    if (formState.authCode.length !== 6) {
      setError({
        state: true,
        message:
          formState.authCode.length === 0
            ? "OTP cannot be empty"
            : "OTP should contain 6 digits",
      });
    } else {
      if (getValues("password") != getValues("confirmPassword")) {
        // There is a password error, set the error state

        setAlert({
          message: "Password do not match",
          type: "error",
        });
      } else {
        // Call onSubmit only if there are no password errors
        onSubmit();
      }
    }
  };

  const onSubmit = async (data) => {
    setIsDisabled(true);
    try {
      await Auth.forgotPasswordSubmit(
        formState.email,
        formState.authCode,
        password.current
      );
      onStateChange("signIn");
      updateFormState({ email: "", password: "", authCode: "" });
      setIsDisabled(false);
    } catch (error) {
      setAlert({ message: error.message, type: "error" });
      setError({ state: true, message: "" });
      // updateFormState({ ...formState, authCode: "" });
      setIsDisabled(false);
    }
  };
  const handleClickShowNewPassword = () => setNewPasswordShow(!newPasswordShow);
  const handleClickShowConfirmPassword = () =>
    setConfirmPasswordShow(!confirmPasswordShow);
  return authState === "forgotPasswordSubmit" ? (
    <>
      <Box
        component="form"
        noValidate
        // onSubmit={handleSubmit(onSubmit)}
        className={classes.root}
      >
        {/* {alert.message !== "" && (
          <CAlert
            message={alert.message}
            type={alert.type}
            setAlert={setAlert}
          />
        )} */}
        <Grid
          container
          className={classes.containerHeight}
          style={{
            backgroundImage: `url(${GroupIcon})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <img src={GroupPeople} />

          <Grid item xs={12} sm={12} md={12}>
            <div className={classes.signInContainer} style={{ margin: "auto" }}>
              <div className={classes.signIn}>
                <div
                  style={{
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "row",
                    display: "flex",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <img src={QeaseLogo} style={{ width: "45%" }} />
                </div>
                <Typography
                  sx={{
                    color: "#060543",
                    fontFamily: "Inter",
                    fontSize: "24px",
                    fontStyle: "normal",
                    fontWeight: "700",

                    lineHeight: "normal",
                  }}
                  className={classes.HeadingText}
                >
                  Forgot Password
                </Typography>
                <div className={classes.VerificationContainer}>
                  {/* <div className={classes.verificationHeader}>
                <Typography align="center">Verification</Typography>
                <KeyIcon />
              </div> */}
                  <div className={classes.verifyOTP}>
                    {/* <Typography align="center" className="title">
                  Forgot Password
                </Typography> */}
                    <Typography align="center" className="subTitle">
                      We have sent a verification code to your email id
                    </Typography>
                    <Box
                      component="form"
                      noValidate
                      onSubmit={handleSubmit(onSubmit)}
                      // className={classes.root}
                    >
                      {/* {alert.message !== "" && (
                    <CAlert
                      message={alert.message}
                      type={alert.type}
                      setAlert={setAlert}
                    />
                  )} */}
                      <div className="otpContainer">
                        <OtpInput
                          value={formState.authCode}
                          onChange={handleOTP}
                          numInputs={6}
                          isInputNum={true}
                          inputStyle="otpInput"
                          hasErrored={error.state}
                          errorStyle="otpErrorState"
                        />
                        <p>{error.state ? error.message : "  "}</p>
                      </div>
                      <Box mt={2} display="flex" gap={2} flexDirection="column">
                        <div className={classes.passwordInputWidth}>
                          <Controller
                            name="password"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "Password cannot be empty",
                              minLength: {
                                value: 6,
                                message:
                                  "Password must have at least 6 characters",
                              },

                              pattern: {
                                value:
                                  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/,
                                message: "Password must be alphanumeric",
                              },
                            }}
                            render={({
                              field: { onChange, value },
                              fieldState: { error },
                            }) => (
                              <TextField
                                id="outlined-error-helper-text"
                                label="Password"
                                variant="outlined"
                                type={newPasswordShow ? "text" : "password"}
                                autoComplete="off"
                                value={value}
                                onChange={onChange}
                                placeholder="Password"
                                error={!!error}
                                helperText={error ? error.message : " "}
                                inputProps={{
                                  autoComplete: "off",
                                  form: {
                                    autoComplete: "off",
                                  },
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="start">
                                      {newPasswordShow ? (
                                        <Visibility
                                          style={{
                                            color: "#58ABE4",
                                            height: "52px",
                                          }}
                                          onClick={handleClickShowNewPassword}
                                        />
                                      ) : (
                                        <VisibilityOff
                                          style={{
                                            color: "#58ABE4",
                                            height: "52px",
                                          }}
                                          onClick={handleClickShowNewPassword}
                                        />
                                      )}{" "}
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </div>
                        <div className={classes.passwordInputWidth}>
                          <Controller
                            name="confirmPassword"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "Password cannot be empty",
                              minLength: {
                                value: 6,
                                message:
                                  "Password must have at least 6 characters",
                              },

                              pattern: {
                                value:
                                  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/,
                                message: "Password must be alphanumeric",
                              },
                              validate: (value) =>
                                value === getValues("password") ||
                                "The passwords do not match",
                            }}
                            render={({
                              field: { onChange, value },
                              fieldState: { error },
                            }) => (
                              <TextField
                                id="outlined-error-helper-text"
                                label="ConfirmPassword"
                                variant="outlined"
                                type={confirmPasswordShow ? "text" : "password"}
                                autoComplete="off"
                                value={value}
                                onChange={onChange}
                                placeholder="Confirm Password"
                                error={!!error}
                                helperText={error ? error.message : " "}
                                inputProps={{
                                  autoComplete: "off",
                                  form: {
                                    autoComplete: "off",
                                  },
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="start">
                                      {confirmPasswordShow ? (
                                        <Visibility
                                          style={{
                                            color: "#58ABE4",
                                            height: "52px",
                                          }}
                                          onClick={
                                            handleClickShowConfirmPassword
                                          }
                                        />
                                      ) : (
                                        <VisibilityOff
                                          style={{
                                            color: "#58ABE4",
                                            height: "52px",
                                          }}
                                          onClick={
                                            handleClickShowConfirmPassword
                                          }
                                        />
                                      )}{" "}
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </div>
                      </Box>
                      <p
                        style={{
                          visibility: alert.visible ? "visible" : "hidden",
                          color: "red",
                          margin: "0px",
                          justifyContent: "flex-start",
                        }}
                      >
                        Password do not match
                      </p>
                      <Typography
                        className={classes.signInFooter}
                        align="center"
                        style={{ color: "#000" }}
                      >
                        If you didn't recieve a code!{" "}
                        <span
                          onClick={resendConfirmationCode}
                          className={classes.signInFooterInverse}
                        >
                          Resend
                        </span>
                      </Typography>
                      <div className="actionButtons">
                        <CButton
                          variant="contained"
                          type="submit"
                          style={{ width: "60%" }}
                          disabled={isDisabled}
                          onClick={handleClick}
                        >
                          Submit
                        </CButton>
                      </div>
                    </Box>
                    <Typography
                      className={
                        classes.signInFooter + " " + classes.signInFooterInverse
                      }
                      align="center"
                    >
                      Back to{" "}
                      <span
                        onClick={() => {
                          setAlert({ message: "", type: "" });
                          onStateChange("signIn");
                        }}
                      >
                        Sign In
                      </span>
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>
    </>
  ) : null;
};

export default ForgotPasswordSubmit;
