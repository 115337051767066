require("dotenv").config();

var domain = process.env.REACT_APP_DOMAIN_NAME;
// var host = process.env.REACT_APP_HOST;
// var prod = process.env.REACT_APP_PROD;

if (typeof window !== "undefined") {
  /**check if its a staging server */
  if (window.location.origin === "https://www.qease.in") {
    domain = process.env.REACT_APP_DOMAIN_NAME;
  }
  if (window.location.origin === "https://dev-qmd.hl1.in") {
    domain = process.env.REACT_APP_DOMAIN_NAME;
  }

  if (
    window.location.origin ===
    "http://dev.qmd.hl1.in.s3-website.eu-central-1.amazonaws.com"
  ) {
    domain = process.env.REACT_APP_DOMAIN_NAME;
  }

  /**check if its a prod server */
  if (window.location.origin === "https://qmd.hl1.in") {
    domain = process.env.REACT_APP_PROD;
  }

  /**check if its a local server */
  if (["localhost", "182.1.2.178"].includes(window.location.hostname)) {
    domain = process.env.REACT_APP_HOST;
  }
}

export const constants = {
  DOMAIN: domain,
};
