const convertedVapidKey = urlBase64ToUint8Array("BBXewAEB4dBTrYyEXAR0nx2EXabo_GqAK8FH8cDNN_vQk-MpcogZJZ9wOgfDBkpxqUQs76ZGf1dcr0ikmIvBlBE")

function urlBase64ToUint8Array(base64String) {
    const padding = "=".repeat((4 - base64String.length % 4) % 4)
    // eslint-disable-next-line
    const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/")

    const rawData = window.atob(base64)
    const outputArray = new Uint8Array(rawData.length)

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i)
    }
    return outputArray
}

// function sendSubscription(subscription) {
// console.log('====================================');
// console.log(subscription);
// console.log('====================================');
// return fetch(`http://localhost:8000/notifications/subscribe`, {
//     method: 'POST',
//     body: JSON.stringify({
//         // subscription: subscription,
//         title: "Notified by Precision Ordance",
//         description: "someone buy a product",
//         icon: "https://ichef.bbci.co.uk/news/976/cpsprodpb/9A50/production/_118740593_gettyimages-1231144196.jpg"
//     }),
//     headers: {
//         'Content-Type': 'application/json'
//     }
// })
// }
//conditional render
let clicked = true

export async function subscribeUser() {
    if (clicked) {
        if ('serviceWorker' in navigator) {
            const registration = await navigator.serviceWorker.ready
            if (!registration.pushManager) {
                // console.log('Push manager unavailable.')
                return
            }
            const existedSubscription = await registration.pushManager.getSubscription()
            if (existedSubscription === null) {
                // console.log('No subscription detected, make a request.')
                const newSubscription = await registration.pushManager.subscribe({
                    applicationServerKey: convertedVapidKey,
                    userVisibleOnly: true,
                }).catch(err => console.log(err))
                // console.log('New subscription added.', newSubscription)
                // sendSubscription(newSubscription)
                return newSubscription
            } else {
                // sendSubscription(existedSubscription)
                return existedSubscription
            }
        }
    } else {
        // console.log('Can not reachable to the service worker');
    }
}