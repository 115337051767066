import React, { useState, useEffect } from "react";
import { CModal } from "../component";
import useStyles from "../assests/css/styles";
import { checkDeviceIP } from "../api";
import qrScan from "../assests/icon/qr.png";
import { Box, Button } from "@material-ui/core";
import { useParams } from "react-router-dom";

const WifiList = (props) => {
  const classes = useStyles();
  let { id } = useParams();
  const name = id ? atob(id) : null;
  const [open, setOpen] = useState(true);
  const [checkIPResponse, setCheckIPResponse] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (checkIPResponse) {
      window.open(`http://192.168.4.1?name=${name}`, "_blank");
    }
  });

  useEffect(() => {
    const checkInterval = setInterval(async () => {
      if (!checkIPResponse && !isLoading) {
        setIsLoading(true);
        checkDeviceIP()
          .then((res) => {
            if (res.code === "ERR_NETWORK") {
              setIsLoading(false);
              setCheckIPResponse(true);
            } else setIsLoading(false);
          })
          .catch((err) => {
            // console.log(err);
          });
      }
    }, 1000);
    return () => clearInterval(checkInterval);
  });

  const handleDirect = () => {
    window.open(`http://192.168.4.1?name=${name}`, "_blank");
  };

  return (
    <div className={classes.main}>
      <CModal open={open} handleClose={handleClose}>
        <div>
          <Box>
            <img style={{ width: "90px" }} src={qrScan} alt="QR code" />
          </Box>
          <p>
            Please Connect QMS Device with default password <b>12345678</b>
          </p>
          <p>Make sure your mobile internet is off!</p>
          <p>Now click button to connect</p>
          <Button
            onClick={handleDirect}
            style={{
              backgroundColor: "#0386ee",
              color: "white",
              marginTop: "22px",
            }}
          >
            Connect
          </Button>
        </div>
      </CModal>
    </div>
  );
};

export default WifiList;
