import React from "react";
import "@aws-amplify/ui-react/styles.css";
import useStyles from "../assests/css/styles";
import CAuthenticator from "../component/auth-components/CAuthenticator";
import MainLayout from "./MainLayout";
import { getUserInfo } from "../../../development/src/services/getUserInfo";
// import CContextProvider from "../component/auth-components/CContext";

const LoginAmplify = () => {
  const classes = useStyles();
  const [details, setDetails] = React.useState(true);
  React.useEffect(() => {
    // console.log("LoginDetails1");
    getUserDetails();
  }, []);

  const getUserDetails = async () => {
    // console.log("LoginDetails2");
    setDetails(await getUserInfo());
    const details = await getUserInfo();
    const userName = details.email && details.email.split("@")[0];
    // console.log(userName, "LoginDetails");
  };
  React.useEffect(() => {
    // console.log(
    //   "amplify-authenticator-authState::",
    //   localStorage.getItem("amplify-authenticator-authState")
    // );
  }, []);
  // console.log("LoginDetails", details);
  details.email && console.log(details.email.split("@")[0], "LoginDetails");
  React.useEffect(() => {
    details.email && console.log(details.email.split("@")[0], "LoginDetails");
  }, [details]);

  return (
    // <CContextProvider>
    // <div className={details ? classes.sessionStart : classes.logIn}>
    <div className={details ? "" : classes.logIn}>
      <CAuthenticator>
        <MainLayout userDetailThroughProp={details}/>
      </CAuthenticator>
    </div>
    // </CContextProvider>
  );
};

export default React.memo(LoginAmplify);
