import { TextField, Typography, Grid } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import useStyles from "../../assests/css/SignInCss";
import { ReactComponent as KeyIcon } from "../../assests/icon/ForgotPassword.svg";
import CButton from "./CButton";
import CDiv from "./CDiv";
import { Auth } from "aws-amplify";
import { Controller, useForm } from "react-hook-form";
import CAlert from "../CAlert";
import GroupIcon from "../../assests/images/Group.png";
import QeaseLogo from "../../assests/images/QeaseLogo.png";
import GroupPeople from "../../assests/images/GroupPeople.png";

import AuthPageImage from "./AuthPageImage";

const ForgotPassword = (props) => {
  const classes = useStyles();
  const { formState, authState, onStateChange, updateFormState } = props;
  const { control, handleSubmit, reset } = useForm();
  const [isDisabled, setIsDisabled] = useState(false);
  const [alert, setAlert] = useState({ message: "", type: "" });

  const onSubmit = async (data) => {
    // Send confirmation code to user's email
    setIsDisabled(true);
    try {
      updateFormState({ ...formState, email: data.email });
      await Auth.forgotPassword(data.email);
      onStateChange("forgotPasswordSubmit");
      setIsDisabled(false);
    } catch (error) {
      setAlert({ message: error.message, type: "error" });
      setIsDisabled(false);
    }
  };

  useEffect(() => {
    reset();
  }, [authState]);

  return authState === "forgotPassword" ? (
    <>
      <Box component="form" noValidate className={classes.root}>
        {/* {alert.message !== "" && (
          <CAlert
            message={alert.message}
            type={alert.type}
            setAlert={setAlert}
          />
        )} */}
        <Grid
          container
          className={classes.containerHeight}
          style={{
            backgroundImage: `url(${GroupIcon})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <img src={GroupPeople} />

          <Grid item xs={12} sm={12} md={12}>
            <div className={classes.signInContainer} style={{ margin: "auto" }}>
              <div className={classes.signIn}>
                <div
                  style={{
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "row",
                    display: "flex",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <img src={QeaseLogo} style={{ width: "45%" }} />
                </div>
                <Typography
                  sx={{
                    color: "#060543",
                    fontFamily: "Inter",
                    fontSize: "24px",
                    fontStyle: "normal",
                    fontWeight: "700",

                    lineHeight: "normal",
                  }}
                  className={classes.HeadingText}
                >
                  Forgot Password
                </Typography>

                <Box className={classes.VerificationContainer}>
                  <Box className={classes.verificationHeader}>
                    {/* <Typography align="center" mt={5}>
                      Forgot Password
                    </Typography> */}
                    <p
                      align="centerI"
                      m={4}
                      className="forgotPasswordTitle"
                      style={{
                        display: "inline-block",
                        fontSize: 14,
                        justifyContent: "flex-end",
                        color: "#909090",
                        margin: "0",
                        fontFamily: " Inter",
                        fontSize: " 16px",
                        fontStyle: " normal",
                        fontWeight: "500",
                        lineHeight: "normal",
                      }}
                    >
                      Enter your email id to get OTPs
                    </p>
                  </Box>
                  <Box
                    component="form"
                    noValidate
                    onSubmit={handleSubmit(onSubmit)}
                    // className={classes.root}
                  >
                    <CDiv>
                      <Controller
                        name="email"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: "Email cannot be empty",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Invalid email address",
                          },
                        }}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <TextField
                            id="outlined-error-helper-text"
                            label="Email"
                            variant="outlined"
                            value={value}
                            placeholder="Email"
                            onChange={onChange}
                            autoComplete="off"
                            error={!!error || alert.message !== ""}
                            helperText={
                              error
                                ? error.message
                                : alert.message !== ""
                                ? "User Not Found"
                                : " "
                            }
                          />
                        )}
                      />
                      <CButton
                        style={{ width: "60%" }}
                        variant="contained"
                        type="submit"
                        disabled={isDisabled}
                      >
                        Get OTP
                      </CButton>
                      <Typography
                        className={
                          classes.signInFooter +
                          " " +
                          classes.signInFooterInverse
                        }
                        style={{ color: "black" }}
                        align="center"
                        mt={2}
                      >
                        Back to{" "}
                        <span
                          onClick={() => {
                            setAlert({ message: "", type: "" });
                            onStateChange("signIn");
                          }}
                          style={{ color: "#58abe4" }}
                        >
                          Sign In
                        </span>
                      </Typography>
                    </CDiv>
                  </Box>
                </Box>
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>
    </>
  ) : null;
};

export default ForgotPassword;
