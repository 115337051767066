import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from "@material-ui/core";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { useHistory, useParams } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import {
  getTotalCount,
  getUserImage,
  sendUserDetailsToSelectSlot,
  sendUserDetailsToConfirmSlot,
  getDoctorFormPreference,
  sendUserDetailsFormData,
  getDoctorFormUserPreference,
  getFormData,
  getDeviceAndQueueList,
} from "../api";
import { subscribeUser } from "../subscription";
import moment from "moment";
import Logo from "../assests/images/habilelabs_full_logo.svg";
import { Controller, useForm } from "react-hook-form";
import Checkbox from "@mui/material/Checkbox";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CAlert from "../component/CAlert";
import Alert from "@mui/material/Alert";
import { ReactComponent as UserRegistrationIcon } from "../assests/images/userRegistrationIcon.svg";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
// import { HeaderMock } from "../component/HeaderMock";
import { HeaderSms as HeaderMock } from "../../src/component/analytics/HeaderSms.js";

import { Footer } from "../component/Footer";
import { colorStyles } from "../assests/css/color";
// import Grid from '@material-ui/core/Grid';
import { ReactComponent as ClockWithBlueBackground } from "../assests/images/ClockWithBlueBackground.svg";
import InputLabel from "@mui/material/InputLabel";
// import Select from "@mui/material/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { FormControlLabel, FormGroup, FormLabel } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import { ReactSVG } from "react-svg";
import queueNotFound from "../../src/assests/icon/Queue-not-found.svg";
import SmsHospital from "../../src/assests/images/sms_medical_college.jpg";
// import { useHistory } from "react-router-dom";

// import Box from '@mui/material/Box';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from "@mui/material/Select";
const useStyles = makeStyles((theme) => ({
  textfieldClass: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  logo: {
    color: "#fff",
    display: "flex",
    fontSize: "20px",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "#0386ee",
    backgroundColor: "#07174B",
    width: "100%",
    height: 80,
    // "& img": {
    //   width: 80,
    //   height: 80,
    //   borderRadius: "50%",
    //   backgroundColor: "#fff",import { makeStyles } from "@material-ui/core/styles";

    // },
    "& div": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
      marginRight: 20,
      "& span": {
        fontSize: 30,
        color: "#fff",
        position: "absolute",
        left: "30px",
      },
      "& IconButton": {
        fontSize: 30,
        color: "#fff",
      },
    },
  },
  root: {
    // background: `url(${SmsHospital})`,
    // backgroundSize: "cover",
    // backgroundPosition: "center",
    // height: '100vh',
    // display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'space-between',
    textAlign: "center",
    height: "100vh",
    flexDirection: "column",
    display: "flex",
    justifyContent: "space-between",
  },
  errorMessage: {
    "& div": {
      marginTop: "20px !important",
      zIndex: 12,
    },
    // position:
  },
  loader: {
    top: "50%",
    position: "absolute",
    color: "#0386ee",
    left: "50%",
  },
  main: {
    // boxShadow: '0px 0px 1px #262F56',
    borderRadius: 10,
    width: "70%",
    padding: " 0px 15px",
    textAlign: "initial",
    boxShadow: "none",
    fontFamily: "'Manrope', 'sans-serif'",
    "& p": {
      marginBottom: 6,
      // alignSelf: "center",
    },
    "& .heading": {
      textAlign: "center",
      marginBottom: 20,
    },
    "& .MuiFormControl-root": {
      width: "100%",
    },

    "& .MuiFormControl-fullWidth": {
      // marginBottom: 20,
      "& input": {
        paddingLeft: 7,
      },
    },
    "& .form-control": {
      width: "100%",
      height: 45,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    "& .MuiInput-root": {
      border: "1px solid rgba(0, 0, 0, 0.23)",
      // borderRadius: "10px",
      padding: "5px",
    },
    "& .react-tel-input .selected-flag, & .react-tel-input .flag-dropdown.open .selected-flag":
      {
        borderRadius: 20,
      },
    "& .react-tel-input .form-control.invalid-number": {
      backgroundColor: "#FFFFFF",
      border: "1px solid #0386ee",
      "&:focus": {
        border: "1px solid #0386ee",
      },
    },
    "& .react-tel-input .flag-dropdown": {
      border: "1px solid #0386ee",
      borderRadius: "10px 0px 0px 10px",
    },
    "& .react-tel-input .form-control": {
      border: "1px solid #0386ee",
      borderRadius: "10px",
    },
    "& .react-tel-input .invalid-number-message": {
      position: "absolute",
      zIndex: "1",
      fontSize: "0.75rem",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      left: 0,
      marginTop: 4,
      top: "46px",
      color: "#f44336",
    },
    "& .MuiButton-contained.Mui-disabled": {
      color: "hsl(240deg 6% 87%)",
      boxShadow: "none",
      backgroundColor: "hsl(206deg 100% 73%)",
    },
    "& .MuiButton-contained": {
      color: "white",
      backgroundColor: "#0386ee",
      // borderRadius: "40px",
      height: "55px",
      marginTop: 10,
      marginBottom: 15,
    },
    "& .MuiButton-contained:hover": {
      backgroundColor: "#fff",
      color: "#0386ee",
      border: "2px solid #0386ee",
    },
    "& .MuiGrid-spacing-xs-3 > .MuiGrid-item": {
      padding: "0px 10px",
      marginBottom: "20px",
    },
    flex: 1,
    display: "flex",
    margin: "0 auto",
  },
  // inputError: {
  //   border: "1px solid #f44336 !important",
  // },
  warning: {
    color: "red",
    fontSize: 10,
  },
  powered: {
    textAlign: "right",
    margin: 20,
    "& span": {
      color: "#0386ee",
    },
  },
  "e-input-focus": {
    border: "1px solid black !important",
  },
  slotBtn: {
    marginTop: "20px",
    padding: 5,
    display: "inline-block",
    "& button": {
      backgroundColor: "#e3ffe9",
      color: "black",
    },
  },
  calendraPaper: {
    // top: "70px !important",
    // left: "90% !important",
    transform: "translate(-100%, 0) !important",
    // "@media screen and (max-width: 1080px)": {},
    // "@media screen and (max-width: 1280px)": {
    //   transform: "translate(-90%, 0) !important",
    // },
  },
  smsImage: {
    maxHeight: "375px",
    width: "100%",
    objectFit: "cover",
    maxWidth: "100%",
  },
}));
const customStyle = {
  height: "30px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  bottom: "0px",
  position: "absolute",
  width: "100%",
  // backgroundColor: "red",
  // height: 30,
};
const Sms = () => {
  const history = useHistory();

  const [minDateCalender, setMinDateCalender] = useState("");
  const [maxDateCalender, setMaxDateCalender] = useState("");
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalNumber] = useState(0);
  const [value, setValue] = useState({ minDateCalender });
  const [deviceList, setDeviceList] = useState([]);
  const [userSelectOption, setUserSelectOption] = React.useState("");

  let [queueName, setQueueName] = useState("");
  const {
    control,
    // handleSubmit,
    formState: { errors },
  } = useForm();
  const [checked, setChecked] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  // const handleChange = (event: SelectChangeEvent) => {
  //   setUserSelectOption(event.target.value as string);
  // };
  const handleChange = (event) => {
    setUserSelectOption(event.target.value);
  };
  // const handleChange = (event) => {
  //   setChecked(event.target.checked);
  // };
  // // console.log("formDataformData", formData.testNumber);
  // //  formData.testNumber ?  console.log("formDataformData", formData.testNumber) : null
  const fetchDeviceAndQueueList = () => {
    let user = "shreyansh.jain";
    // setIsLoading(true);
    getDeviceAndQueueList(user)
      .then((res) => {
        // setIsLoading(false);
        setDeviceList(res);
        console.log(res);
      })
      .catch((err) => {
        // setIsLoading(false);
        // console.log(err.message);
      });
  };
  useEffect(() => {
    fetchDeviceAndQueueList();
  }, []);
  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic here
    let user = "shreyansh.jain";
    deviceList.map((item) => {
      if (item.includes(userSelectOption)) {
        const hashId = btoa(item.split(".")[0]);
        console.log("Selected option:", "http://localhost:3000/tr/" + hashId);
        // console.log("userSelectOption", item.split(".")[0]);
        // history.push("/http://localhost:3000/tr/" + hashId);
        history.push(`/tr/${hashId}`);
      }
    });
    // const hashId = btoa(
    //   `${user.replace(".", "")}_${userSelectOption}_${userSelectOption}`
    // );
    // console.log("Selected option:", "http://localhost:3000/tr/" + hashId);
    console.log("Selected option:", userSelectOption);
  };
  deviceList.map((list, index) => {
    const device = list.split("_")[1];
    const queue =
      list.split("_")[2] &&
      list.split("_")[2].substring(0, list.split("_")[2].indexOf("."));
    console.log("device", device);
    console.log("queue", queue);
  });
  return (
    <div className={classes.root}>
      <HeaderMock />

      {isLoading ? (
        <CircularProgress className={classes.loader} />
      ) : (
        <Grid container xs={12}>
          {/* <Grid item xs={12}>
            <Typography
              variant="h6"
              className="heading"
              style={{ margin: 0, padding: "1rem" }}
            >{`Enroll yourself in the Queue of ${
              queueName ? queueName : ""
            } to get update about your turn.`}</Typography>
          </Grid> */}

          <div
            style={{
              alignItems: "center",
              // justifyContent: "center",
              display: "flex",
              width: "100%",
              marginTop: "10px",
              marginBottom: "10px",
              flexDirection: "column",
            }}
          >
            {/* <UserRegistrationIcon /> */}
            <img
              className="smsImage"
              style={{ maxWidth: "100%" }}
              src={SmsHospital}
              alt=""
            />
            <Typography
              variant="h6"
              className="heading"
              style={{ margin: 0, padding: "1rem" }}
            >
              {" "}
              {`Enroll yourself in the Queue of `}
              <strong>SMS hospital</strong>
              {` to get update about your turn.`}
            </Typography>
            <form
              onSubmit={handleSubmit}
              style={{ marginLeft: "10px", marginRight: "10px" }}
            >
              <FormControl fullWidth style={{ marginBottom: "20px" }}>
                <InputLabel id="demo-simple-select-label">
                  Select Department
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={"General"}
                  label="Select Department"
                  // onChange={handleChange}
                  readOnly={true}
                  style={{ minWidth: 200 }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 200, // Adjust as needed
                        width: 250,
                        // marginTop: theme.spacing(1),
                      },
                    },
                  }}
                >
                  <MenuItem value="General">General</MenuItem>
                  {/* <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem> */}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Doctor to Visit
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={userSelectOption}
                  label="Select Doctor to Visit"
                  onChange={handleChange}
                  style={{ minWidth: 200 }}
                  required
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 200, // Adjust as needed
                        width: 250,
                        // marginTop: theme.spacing(1),
                      },
                    },
                  }}
                >
                  {deviceList.map((list, index) => {
                    const device = list.split("_")[1];
                    const queue =
                      list.split("_")[2] &&
                      list
                        .split("_")[2]
                        .substring(0, list.split("_")[2].indexOf("."));
                    {
                      /* console.log("device", device); */
                    }
                    return <MenuItem value={queue}>{queue}</MenuItem>;
                    {
                      /* console.log("queue", queue); */
                    }
                  })}
                  {/* <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem> */}
                </Select>
              </FormControl>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ mt: 2 }}
                style={{ marginTop: "20px" }}
              >
                Submit
              </Button>
            </form>
          </div>
        </Grid>
      )}
      {!isLoading && !showCalendar && <Footer style={customStyle} />}
    </div>
  );
};

export default Sms;
