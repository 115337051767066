import {
  CircularProgress,
  Box,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import wifiline from "../assests/icon/wifi-line.svg";
import { checkDeviceIP, getDeviceAndQueueList } from "../api";
import useStyles from "../assests/css/styles";
import { getUserInfo } from "../services/getUserInfo";
import { ReactSVG } from "react-svg";
import deviceNotFound from "../assests/icon/Device-not-found.svg";
import qrScan from "../assests/icon/qr.png";
import { CModal } from "../component";
import { useParams } from "react-router-dom";

const WifiSetting = (props) => {
  const classes = useStyles();
  let { id } = useParams();
  const name = id ? atob(id) : null
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deviceList, setDeviceList] = useState([]);
  const [checkIPResponse, setCheckIPResponse] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    getUserDetails();
  }, []);

  useEffect(() => {
    const checkInterval = setInterval(async () => {
      if (!checkIPResponse && !loading) {
        setLoading(true);
        checkDeviceIP()
          .then((res) => {
            if (res.code === "ERR_NETWORK") {
              setLoading(false);
              setCheckIPResponse(true);
            } else setLoading(false);
          })
          .catch((err) => {
            // console.log(err);
          });
      }
    }, 1000);
    return () => clearInterval(checkInterval);
  });

  useEffect(() => {
    if (checkIPResponse) {
      window.open(`http://192.168.4.1?name=${name}`, "_blank");
    }
  });

  const getUserDetails = async () => {
    const details = await getUserInfo();
    const userName = details.email && details.email.split("@")[0];
    fetchDeviceAndQueueList(userName.replace(/[.-]/g, ""));
  };

  const fetchDeviceAndQueueList = (user) => {
    setIsLoading(true);
    getDeviceAndQueueList(user)
      .then((res) => {
        setIsLoading(false);
        setDeviceList(res);
      })
      .catch((err) => {
        setIsLoading(false);
        // console.log(err.message);
      });
  };

  const handleListClick = () => {
    setOpen(true);
  };

  return (
    <div>
      {!isLoading ? (
        deviceList && deviceList.length ? (
          deviceList.map((list, index) => {
            const device = list.split("_")[1];
            return (
              <div>
                <ListItem
                  className={classes.wifiList}
                  component="div"
                  key={index}
                >
                  <ListItemIcon>
                    <ReactSVG src={wifiline} />
                  </ListItemIcon>
                  <ListItemText
                    onClick={() => handleListClick(device)}
                    primary={device}
                  />
                </ListItem>
              </div>
            );
          })
        ) : (
          <Box className={classes.noDevice}>
            <ReactSVG src={deviceNotFound} />
            <p>Wifi not found.</p>
          </Box>
        )
      ) : (
        <CircularProgress className={classes.wifiLoader} color="primary" />
      )}
      <CModal open={open} handleClose={handleClose} bgClick={true}>
        <div>
          <Box>
            <img src={qrScan} alt="QR code" />
          </Box>
          <p>
            Please reset your device first then scan the QR code behind the
            device.
          </p>
        </div>
      </CModal>
    </div>
  );
};

export default WifiSetting;
