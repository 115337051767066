import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: "#060543",
    color: "white",
    borderRadius: "23px",
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    borderBottom: "none",
    backgroundColor: "white",
    color: "black",
    textAlign: "center",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    backgroundColor: "white",
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ConfirmationAlert(props) {
  const { open, title, description, handleClose, handleConfirm } = props;

  return (
    <div>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={open}
        handleClose={handleClose}
      >
        <p
          onClose={handleClose}
          style={{ textAlign: "center", backgroundColor: "#060543" }}
        >
          {title}
        </p>
        <DialogContent dividers>
          <Typography gutterBottom>{description}</Typography>
          <Button
            variant="text"
            size="medium"
            onClick={handleClose}
            style={{
              backgroundColor: "red",
              margin: "13px",
              color: "white",
            }}
          >
            Cancel
          </Button>
          <Button
            variant="text"
            size="medium"
            onClick={handleConfirm}
            style={{
              backgroundColor: "#060543",
              margin: "13px",
              color: "white",
            }}
          >
            Confirm
          </Button>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
