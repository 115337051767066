import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { BillBoardScreen, Device, Setting, SettingDetails, EditProfile, VoiceMessage, VoiceMessageDetails, CustomForm, CustomFormDetails } from "./component";
import {
  Analytics,
  HomePage,
  LoginAmplify,
  TokenRegistration,
  UserSignUp,
  WifiList,
  RegistrationSuccess,
  Sms
} from "./pages";
import PrivateRoute from "./services/PrivateRoute";
import CContextProvider from "./component/auth-components/CContext";
import ValidateUserRoute from "./component/Router/ValidateUserRoute";
import ErrorBoundary from "./component/PageNotFound";

function App(props) {
  React.useEffect(() => {
    // console.log(
    //   "amplify-authenticator-authState::",
    //   localStorage.getItem("amplify-authenticator-authState")
    // );
    {
      localStorage.getItem("amplify-authenticator-authState") == "signIn"
        ? setisUserLoggedIn(true)
        : setisUserLoggedIn(false);
    }
  }, []);
  const [isUserLoggedIn, setisUserLoggedIn] = React.useState(false);
  return (
    <React.Fragment>
    <ErrorBoundary>
      <CContextProvider>
        <Router>
          <Switch>
            <Route exact path="/" component={LoginAmplify} />
            <PrivateRoute exact path="/sms" component={Sms} />
            <ValidateUserRoute path="/analytics/:id" component={Analytics} />
            <ValidateUserRoute path="/queue/:id" component={HomePage} />
            <PrivateRoute exact path="/tr/:device" component={UserSignUp} />     
            <Route exact path="/token/:id" component={TokenRegistration} />
            <ValidateUserRoute path="/device/:id" component={WifiList} />
            <ValidateUserRoute exact path="/device-setting" component={Device}/>
            <ValidateUserRoute path="/bill-board" component={BillBoardScreen} />
            <ValidateUserRoute path="/setting" component={Setting} />
            <ValidateUserRoute path="/edit-profile" component={EditProfile} />
            <ValidateUserRoute path="/device-setting/:id" component={SettingDetails} />
            <ValidateUserRoute path="/voice-message" component={VoiceMessage} />
            <ValidateUserRoute path="/message-details/:id" component={VoiceMessageDetails} />
            <ValidateUserRoute path="/custom-form" component={CustomForm} />
            <ValidateUserRoute path="/custom-details/:id" component={CustomFormDetails} />
            <Route
              path="/RegistrationSuccess/:id"
              component={RegistrationSuccess}
            />
            <Route path="*" component={LoginAmplify} />
          </Switch>
        </Router>
      </CContextProvider>
      </ErrorBoundary>
    </React.Fragment>
  );
}
export default App;
