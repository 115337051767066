import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import AWSIoTData from "aws-iot-device-sdk";
import AWSConfiguration from "../aws-iot-configuration.js";

function MqttDeleteConnection(props) {
  const { totalCountTopic, removeUserHandler } = props;
  //   const [subscribedTopics, setSubscribedTopics] = useState([]);

  // isConnected and mqttClient strictly used for publishing;
  // Subscriptions are instead handled in child MQTTSubscription components
  const [isConnected, setIsConnected] = useState(false);
  // const [mqttClient, setMqttClient] = useState();

  useEffect(() => {
    connectToAwsIot();
    // console.log("MqttDeleteConnection")
  }, []); // the empty [] ensures only run once

  //   useEffect(() => {
  //     if (isConnected) handleSubscriptionRequest();
  //   }, [isConnected]);

  async function connectToAwsIot() {
    // This connection/function is only for publishing messages;
    // Subscriptions each get their own child object with separate connections.

    // mqtt clients require a unique clientId; we generate one below
    var clientId =
      "iotcognito-smart-queue-" + Math.floor(Math.random() * 100000 + 1);

    // get credentials and, from them, extract key, secret key, and session token
    // Amplify's auth functionality makes this easy for us...
    var currentCredentials = await Auth.currentCredentials();
    var essentialCredentials = Auth.essentialCredentials(currentCredentials);

    // Create an MQTT client
    var newMqttClient = AWSIoTData.device({
      region: AWSConfiguration.region,
      host: AWSConfiguration.host,
      clientId: clientId,
      protocol: "wss",
      maximumReconnectTimeMs: 8000,
      debug: false,
      accessKeyId: essentialCredentials.accessKeyId,
      secretKey: essentialCredentials.secretAccessKey,
      sessionToken: essentialCredentials.sessionToken,
    });

    // console.log(
    //   "MqttDeleteConnectionPublisher trying to connect to AWS IoT for clientId:",
    //   clientId
    // );
    // On connect, update status
    newMqttClient.on("connect", function () {
      setIsConnected(true);
      newMqttClient.subscribe(totalCountTopic.replace('.', ''));
      // console.log(
      //   "MqttDeleteConnectionPublisher connected to AWS IoT for clientId:",
      //   clientId
      // );
    });

    // add event handler for received messages
    newMqttClient.on("message", function (topic, payload) {
      // console.log("MqttDeleteConnection", topic);
      // console.log("MqttDeleteConnection", payload.toString());
      const data = payload.toString();

      // console.log(data.Deleted);
      // console.log(`MqttDeleteConnectionSubscribed to ${topic}`);
      if (
        data.localeCompare({
          Deleted: "Person",
        })
      ) {
        // console.log("MqttDeleteConnectionSUCCES___..");
        removeUserHandler();
      }
      //   topic === currentCountTopic &&
      //     setCurrentNumber(data && Number(JSON.parse(data).Count));
      //   topic === totalCountTopic &&
      //     setTotalNumber(data && JSON.parse(data));
    });

    // typeof setMqttClient === "function" && setMqttClient(newMqttClient);
  }

  // function handlePublishRequest(e) {
  //   mqttClient.publish(topic, publishData);
  // }

  //   function handleSubscriptionRequest(e) {
  //     if (subscribedTopics.includes(currentCountTopic)) {
  //       console.log(
  //         `You are already subscribed to currentCountTopic '${currentCountTopic}'!`
  //       );
  //       console.log(
  //         `You are already subscribed to totalCountTopic '${totalCountTopic}'!`
  //       );
  //     } else {
  //       setSubscribedTopics((prevTopics) => [
  //         ...prevTopics,
  //         currentCountTopic,
  //         totalCountTopic,
  //       ]);
  //     }
  //   }

  return <></>;
}

export default MqttDeleteConnection;
