import * as React from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  TextField,
  Modal,
} from "@material-ui/core";
import { getUserInfo } from "../services/getUserInfo";
import useStyles from "../assests/css/profileStyle";
import "../assests/css/style.css";
import Logo from "../assests/images/habilelabs_full_logo.svg";
import { useHistory } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import { getUserImage, uploadImageName } from "../api";
import { CModal, CroppieModal } from ".";
import ImageCropper from "./ImageCropper";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Header } from "../../src/component/analytics/Header";
import { CContext } from "../component/auth-components/CContext";

const EditProfile = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const [userDetails, setUserDetails] = React.useState({});
  const name = userDetails.email && userDetails.email.split("@")[0];
  const [userName, setUserName] = React.useState("");
  const [openName, setOpenName] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [userNameNew, setUserNameNew] = React.useState("");
  // const [showError, setShowError] = React.useState(false);
  const [showImageError, setImageShowError] = React.useState(false);
  // const [croppedImage, setCroppedImage] = React.useState("");
  const handleOpenName = () => {
    setUserNameNew(userName);
    setOpenName(true);
  };
  const [imageToCrop, setImageToCrop] = React.useState(undefined);
  const [croppedImage, setCroppedImage] = React.useState(undefined);
  const [base64Data, setBase64Data] = React.useState("");
  const handleCloseName = () => setOpenName(false);
  const [img, setImg] = React.useState();
  // const [check, setCheck] = React.useState(true);
  const [imageUpload, setImageUpload] = React.useState(false);
  const [selectedImg, setSelectedImg] = React.useState(null);
  const [imgFromCloud, setImgFromCloud] = React.useState();
  // const [newState, setNewState] = useState("");
  // const id = btoa(name && name.replace(".", ""));
  console.log("localImeg", localStorage.getItem(`${name}_img`));

  const handleCloseImageUpload = () => {
    setImageUpload(false);
  };

  const onUploadFile = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();

      reader.addEventListener("load", () => {
        const image = reader.result;

        setImageToCrop(image);
        console.log("uploadImage", image);
      });

      reader.readAsDataURL(event.target.files[0]);
    }
  };
  const handleImg = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type === "image/jpeg" || file.type === "image/png") {
        // if (file.size > 2500000) {
        // return setImageShowError("Image should be only max 2MB");
        // }
        setImageUpload(true);
        setImageShowError(false);
        setSelectedImg(file);
        onUploadFile(e);
      } else {
        return setImageShowError("Only images are allow");
      }
    }
    // if (file) {
    //   getBase64(file).then((base64) => {
    //     localStorage["img"] = base64;
    //     saveImageAndUserName(userName, base64.split(",")[1]);
    //     setCheck(!check);
    //   });
    // }
  };

  React.useEffect(() => {
    // console.log(localStorage.getItem("img"));
    getUserDetails();
  }, []);
  React.useEffect(() => {
    localStorage.setItem("BottomNavigationScreenIndex", 2);

    // bottomTabValueHandler(2);
    // console.log("bottomTabValueHandler")
    // localStorage.setItem("BottomNavigationScreenIndex", 0);
    // return () => {
    //   localStorage.setItem("BottomNavigationScreenIndex", 0);
    // };
  }, []);

  React.useEffect(() => {
    if (name) {
      getUserEmailUserName();
    }
  }, [name]);
  // React.useEffect(() => {
  //   getUserEmailUserName();
  // }, [img]);

  // React.useEffect(() => {
  //   console.log("useEffectuseEffect==>>>>>>>>>", localStorage.getItem("img"));
  //   setImg(localStorage.getItem("img"));
  // }, [img]);

  React.useEffect(() => {
    // localStorage.setItem(`${name}_img`, base64.split(",")[1]);
    console.log("settingIMG", localStorage.getItem(`${name}_img`));
    localStorage.getItem(`${name}_img`) &&
      setImg(localStorage.getItem(`${name}_img`));
  }, [img]);

  const getUserDetails = async () => {
    const details = await getUserInfo();
    console.log("localImeg", details);
    setUserDetails(details);
  };

  // const signOutBtn = () => {
  //   bottomTabValueHandler(0);
  //   Auth.signOut();
  // };

  const changeUserName = () => {
    setUserName(userNameNew);
    // const imageBase64 =
    //   localStorage.getItem("img") && localStorage.getItem("img");
    //   console.log("saveImageAndUserName", userNameNew, imageBase64);
    //   console.log("saveImageAndUserName", localStorage.getItem("img"));
    saveImageAndUserName(userNameNew);
    handleCloseName();
  };

  // const saveImageAndUserName = (updateName, image) => {
  //   const userName = name + "_" + updateName;

  //   uploadImageName(userName, image).then((res) => {
  //     if (res.status === 200) {
  //       const data = res.data.files.link;
  //       setImg(data);
  //     }
  //   });
  // };
  const saveImageAndUserName = async (updateName, image) => {
    const userName = name + "_" + updateName;
    console.log("saveImageAndUserName", userName, image);
    const out = await uploadImageName(userName, image);
    console.log("outImg", out);
    console.log("outImg", out.data.files.link);
    console.log("settingIMG", out.data.files.link);
    // setImg(out.data.files.link);
    // localStorage.setItem("img", out.files.link);

    // getUserEmailUserName();
  };

  // const enterDetails = () => {
  //   setUserName(userNameNew);
  //   // handleCloseImgName();
  // };
  const convertBlobToBase64 = (blobUrl) => {
    // Create a new XMLHttpRequest
    const xhr = new XMLHttpRequest();
    xhr.responseType = "blob";

    xhr.onload = function () {
      const reader = new FileReader();

      reader.onloadend = function () {
        const base64String = reader.result.split(",")[1];
        setBase64Data(base64String);
      };

      reader.readAsDataURL(xhr.response);
    };

    xhr.open("GET", blobUrl);
    xhr.send();
  };
  const getUserEmailUserName = async () => {
    setIsLoading(true);
    const imageData = await getUserImage(name);
    console.log("localImeg", imageData.files.link);

    setImgFromCloud(imageData.files.link);
    console.log("settingIMG", imageData.files.link);
    setImg(imageData.files.link);
    // localStorage.setItem("img", base64);
    const data = imageData.files.link;
    console.log(
      "localImeg",
      data
        .substr(data.lastIndexOf("/") + 1)
        .split(".")[0]
        .split("_")[1]
    );
    setUserName(
      data
        .substr(data.lastIndexOf("/") + 1)
        .split(".")[0]
        .split("_")[1]
    );

    if (imageData.files.link) {
      setIsLoading(false);
    }
  };
  
  // const getUserEmailUserName = () => {
  //   setIsLoading(true);
  //   getUserImage(name)
  //     .then((res) => {
  //       if (res.status === 200) {
  //         setIsLoading(false);
  //         const data = res.data && res.data.files.link;
  //         setImg(data);
  //         // setUserName(data.lastIndexOf("/") + 1);
  //         setUserName(
  //           data
  //             .substr(data.lastIndexOf("/") + 1)
  //             .split(".")[0]
  //             .split("_")[1]
  //         );
  //       } else setIsLoading(false);
  //     })
  //     .catch((err) => setIsLoading(false));
  // };

  // const handleCloseChangePassword = () => {
  // setOldPassword("");
  // setNewPassword("");
  // setConfirmPassword("");
  // setOpenChangePassword(false);
  // };

  // const uploadImage = (base64) => {
  //   console.log("uploadImage", base64);
  //   // convertBlobToBase64(blobUrl);

  //   // const xhr = new XMLHttpRequest();
  //   // xhr.responseType = "blob";

  //   // xhr.onload = function () {
  //   //   const reader = new FileReader();

  //   //   reader.onloadend = function () {
  //   //     const base64String = reader.result.split(",")[1];
  //   //     setBase64Data(base64String);
  //   //   };

  //   //   reader.readAsDataURL(xhr.response);
  //   // };

  //   // xhr.open("GET", base64);
  //   // xhr.send();

  //   if (img && img.size > 2500000) {
  //     handleCloseImageUpload();
  //     return setImageShowError("Image should be only max 2MB");
  //   }
  //   setImageShowError(false);

  //   console.log("uploadImage", base64);
  //   localStorage.setItem(`${name}_img`, base64);
  //   localStorage.setItem("img", base64.split(",")[1]);
  //   saveImageAndUserName(userName, base64.split(",")[1]);
  //   setImg(base64)
  //   handleCloseImageUpload();
  //   // getBase64(img).then((base64) => {
  //   //   // localStorage["img"] = base64;
  //   //   // console.log("base64", base64);
  //   //   setImg(base64);
  //   //   console.log("base64.split(", ")[1]", base64);
  //   //   localStorage.setItem("img", base64);
  //   //   saveImageAndUserName(userName, base64.split(",")[1]);
  //   //   handleCloseImageUpload();
  //   // });
  // };
  const uploadImage = (base64) => {
    console.log("uploadImage", base64);
    // Check the size of the cropped image
    const imageData = base64.split(",");
    const sizeInMB = (imageData[1].length * 3) / 4 / 1024 / 1024; // Calculate image size in MB

    if (sizeInMB > 2.5) {
      // If the image is larger than 2.5 MB, reduce its size
      reduceImageSize(imageData[1], (resizedImage) => {
        // Handle the resized image (e.g., save or upload)
        // You can replace the following code with your logic
        console.log("Resized Image:", resizedImage);
        // Now you can save or upload the resized image
        localStorage.setItem(`${name}_img`, resizedImage);
        setImg(resizedImage);
        saveImageAndUserName(userName, resizedImage);

        handleCloseImageUpload();
      });
    } else {
      // The image is within the size limit, save or upload it directly
      // You can replace the following code with your logic
      localStorage.setItem(`${name}_img`, base64);
      saveImageAndUserName(userName, base64.split(",")[1]);

      setImg(base64);
      handleCloseImageUpload();
    }
  };

  function reduceImageSize(base64Image, callback) {
    const img = new Image();
    img.src = "data:image/jpeg;base64," + base64Image;

    img.onload = () => {
      const canvas = document.createElement("canvas");
      const maxWidth = 1024; // You can adjust this as needed
      const maxHeight = 1024; // You can adjust this as needed

      let width = img.width;
      let height = img.height;

      // Resize the image while preserving its aspect ratio
      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }
      }

      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, width, height);

      // Convert the canvas to a base64 image
      const resizedImage = canvas.toDataURL("image/jpeg");

      callback(resizedImage);
    };
  }

  // const uploadImage = () => {
  //     if (selectedImg.size > 2500000) {
  //         handleCloseImageUpload();
  //         return setImageShowError("Image should be only max 2MB");
  //     }
  //     setImageShowError(false);
  //     getBase64(selectedImg).then((base64) => {
  //         // localStorage["img"] = base64;
  //         // console.log("base64", base64);
  //         setImg(base64);
  //         // console.log("base64.split(", ")[1]", base64);
  //         localStorage.setItem("img", base64);
  //         saveImageAndUserName(userName, base64.split(",")[1]);
  //         handleCloseImageUpload();
  //     });
  //     // const uploadImage = (base64) => {
  //     // if (img.size > 2500000) {
  //     //   handleCloseImageUpload();
  //     //   return setImageShowError("Image should be only max 2MB");
  //     // }
  //     // setImg(base64);
  //     // console.log("base64.split(", ")[1]", base64);
  //     // localStorage.setItem("img", base64);
  //     // saveImageAndUserName(userName, base64.split(",")[1]);
  //     // handleCloseImageUpload();
  //     // getBase64(img).then((base64) => {
  //     //   // localStorage["img"] = base64;
  //     //   // console.log("base64", base64);
  //     //   setImg(base64);
  //     //   console.log("base64.split(", ")[1]", base64);
  //     //   localStorage.setItem("img", base64);
  //     //   saveImageAndUserName(userName, base64.split(",")[1]);
  //     //   handleCloseImageUpload();
  //     // });
  // };
  console.log("imgimg", img);
  return (
    <div>
      <Grid container className={classes.main}>
        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.profile}>
          <Header />

          {/* <Box className={classes.rootLogo} style={{ height: "14vh" }}>
            <IconButton
              component="span"
              onClick={() => {
                history.goBack();
              }}
            >
              <ChevronLeftIcon />
            </IconButton>
            <img src={Logo} alt="logo" />
          </Box> */}
          <Box className={classes.editProfile} >
            <p>Edit your profile here</p>
            <img
              src={img}
              key={img}
              name="file"
              className="img-thumbnail"
              height={100}
              width={100}
              alt="profile"
            />
            <input
              id="selectImage"
              type="file"
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={handleImg}
              // onChange={onUploadFile}

              accept="image/*"
              className={classes.input}
            />
            <label
              className={classes.icon}
              style={{ marginTop: "-60px" }}
              htmlFor="selectImage"
            >
              {<PhotoCamera />}
            </label>

            {showImageError && <span>{showImageError}</span>}
            <CModal
              maxWidth="xs"
              open={imageUpload}
              handleClose={handleCloseImageUpload}
              bgClick={true}
            >
              {/* {console.log("selectedImg", selectedImg)} */}
              {/* <CroppieModal
                image={selectedImg}
                uploadImage={uploadImage}
                uploading={false}
                closeModal={handleCloseImageUpload}
                modalTitle={"Change profile picture"}
                error={showImageError}
                setImage={setCroppedImage}
              /> */}
              <ImageCropper
                imageToCrop={imageToCrop}
                onImageCropped={(croppedImage) => setCroppedImage(croppedImage)}
              />
              <Grid container>
                <Grid item xs={6} align={"left"}>
                  <div className={classes.cancelBtn}>
                    <Button variant="outlined" onClick={handleCloseImageUpload}>
                      Cancel
                    </Button>
                  </div>
                </Grid>
                {console.log("croppedImage", croppedImage)}

                <Grid item xs={6} align={"right"}>
                  <div className={classes.uploadBtn}>
                    <Button
                      variant="outlined"
                      disabled={!selectedImg && !showImageError}
                      onClick={() => uploadImage(croppedImage)}
                    >
                      Upload
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </CModal>
            {/* <div>
              <input
                type="file"
                accept="image/*"
                onChange={onUploadFile}
                style={{ display: "none" }}
                id="fileInput"
              />
              <label htmlFor="fileInput">Select Image</label>{" "}
              <div>
                <ImageCropper
                  imageToCrop={imageToCrop}
                  onImageCropped={(croppedImage) =>
                    setCroppedImage(croppedImage)
                  }
                />
              </div>
              {croppedImage && (
                <div>
                  <h2>Cropped Image</h2>
                  <img alt="Cropped Img" src={croppedImage} />
                </div>
              )} */}
            {/* {imageToCrop && (
                <div>
                  <h2>Cropped Image</h2>
                  <img alt="Cropped Img" src={imageToCrop} />
                </div>
              )} */}
            {/* </div> */}
            <Box className={classes.userProfile}>
              <div style={{ paddingLeft: 15, paddingTop: "38px" }}>
                <Typography
                  className={classes.userName}
                  gutterBottom
                  variant="h5"
                  component="div"
                >
                  {userName}
                </Typography>
                <label>
                  <IconButton
                    color="primary"
                    aria-label="update name"
                    component="span"
                    onClick={handleOpenName}
                  >
                    <BorderColorOutlinedIcon color="primary" />
                  </IconButton>
                </label>
              </div>
            </Box>
            <Box
              style={{
                borderRadius: "12px",
                backgroundColor: "white",
                marginTop: "30px",
              }}
            >
              <Button
                onClick={() => {
                  history.goBack();
                }}
              >
                Done
              </Button>
            </Box>
          </Box>
          <Modal
            open={openName}
            onClose={handleCloseName}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              className={classes.modal}
              style={{ backgroundColor: "#060543" }}
            >
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className={classes.modalHeader}
              >
                Enter new name
              </Typography>
              <TextField
                fullWidth
                className={classes.modalBody}
                required
                value={userNameNew}
                onChange={(e) => {
                  setUserNameNew(e.target.value);
                }}
              />
              <Box className={classes.modalFooter}>
              {!userNameNew && (
                  <p
                    style={{
                      color: "red",
                      backgroundColor: "white",
                      margin: 0,
                    }}
                  >
                    Please enter name
                  </p>
                )}
                <Button
                  variant="text"
                  size="medium"
                  onClick={handleCloseName}
                  style={{
                    backgroundColor: "red",
                    margin: "13px",
                    color: "white",
                  }}
                >
                  cancel
                </Button>
                <Button
                  variant="text"
                  size="medium"
                  onClick={changeUserName}
                  disabled={!userNameNew}
                  style={{
                    backgroundColor: "#060543",
                    margin: "13px",
                    color: "white",
                  }}
                >
                  confirm
                </Button>
              </Box>
            </Box>
          </Modal>
        </Grid>
      </Grid>
    </div>
  );
};

export default EditProfile;

// import * as React from "react";
// import {
//   Box,
//   Button,
//   Grid,
//   Typography,
//   TextField,
//   Modal,
// } from "@material-ui/core";
// import { getUserInfo } from "../services/getUserInfo";
// import useStyles from "../assests/css/profileStyle";
// import "../assests/css/style.css";
// import Logo from "../assests/images/habilelabs_full_logo.svg";
// import { useHistory } from "react-router-dom";
// import IconButton from "@mui/material/IconButton";
// import PhotoCamera from "@mui/icons-material/PhotoCamera";
// import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
// import { getUserImage, uploadImageName } from "../api";
// import { CModal, CroppieModal } from ".";
// import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
// import { Header } from "../../src/component/analytics/Header";

// const EditProfile = (props) => {
//   const history = useHistory();
//   const classes = useStyles();
//   const [userDetails, setUserDetails] = React.useState({});
//   const name = userDetails.email && userDetails.email.split("@")[0];
//   const [userName, setUserName] = React.useState("");
//   const [openName, setOpenName] = React.useState(false);
//   const [isLoading, setIsLoading] = React.useState(false);
//   const [userNameNew, setUserNameNew] = React.useState("");
//   // const [showError, setShowError] = React.useState(false);
//   const [showImageError, setImageShowError] = React.useState(false);
//   const [croppedImage, setCroppedImage] = React.useState("");
//   const handleOpenName = () => {
//     setUserNameNew(userName);
//     setOpenName(true);
//   };
//   const handleCloseName = () => setOpenName(false);
//   const [img, setImg] = React.useState(localStorage.getItem("img") && localStorage.getItem("img"));
//   // const [check, setCheck] = React.useState(true);
//   const [imageUpload, setImageUpload] = React.useState(false);
//   const [selectedImg, setSelectedImg] = React.useState(null);
//   // const [newState, setNewState] = useState("");
//   // const id = btoa(name && name.replace(".", ""));
//   console.log("localImeg", localStorage.getItem("img"));
//   const handleCloseImageUpload = () => {
//     setImageUpload(false);
//   };

//   const handleImg = (e) => {
//     const file = e.target.files[0];
//     if (file) {
//       if (file.type === "image/jpeg" || file.type === "image/png") {
//         if (file.size > 2500000) {
//           return setImageShowError("Image should be only max 2MB");
//         }
//         setImageUpload(true);
//         setImageShowError(false);
//         setSelectedImg(file);
//       } else {
//         return setImageShowError("Only images are allow");
//       }
//     }
//     // if (file) {
//     //   getBase64(file).then((base64) => {
//     //     localStorage["img"] = base64;
//     //     saveImageAndUserName(userName, base64.split(",")[1]);
//     //     setCheck(!check);
//     //   });
//     // }
//   };

//   React.useEffect(() => {
//     // console.log(localStorage.getItem("img"));
//     getUserDetails();
//   }, []);
//   React.useEffect(() => {
//     localStorage.setItem("BottomNavigationScreenIndex", 2);

//     // bottomTabValueHandler(2);
//     // console.log("bottomTabValueHandler")
//     // localStorage.setItem("BottomNavigationScreenIndex", 0);
//     // return () => {
//     //   localStorage.setItem("BottomNavigationScreenIndex", 0);
//     // };
//   }, []);

//   React.useEffect(() => {
//     if (name) {
//       getUserEmailUserName();
//     }
//   }, [name]);
//   // React.useEffect(() => {
//   //   getUserEmailUserName();
//   // }, [img]);

//   // React.useEffect(() => {
//   //   console.log("useEffectuseEffect==>>>>>>>>>", localStorage.getItem("img"));
//   //   setImg(localStorage.getItem("img"));
//   // }, [img]);

//   React.useEffect(() => {
//     localStorage.getItem("img") &&  setImg(localStorage.getItem("img"));
//   }, [img]);

//   const getUserDetails = async () => {
//     const details = await getUserInfo();
//     setUserDetails(details);
//   };

//   // const signOutBtn = () => {
//   //   bottomTabValueHandler(0);
//   //   Auth.signOut();
//   // };

//   const changeUserName = () => {
//     setUserName(userNameNew);
//     const imageBase64 =
//       localStorage.getItem("img") && localStorage.getItem("img").split(",")[1];
//     saveImageAndUserName(userNameNew, imageBase64);
//     handleCloseName();
//   };

//   // const saveImageAndUserName = (updateName, image) => {
//   //   const userName = name + "_" + updateName;

//   //   uploadImageName(userName, image).then((res) => {
//   //     if (res.status === 200) {
//   //       const data = res.data.files.link;
//   //       setImg(data);
//   //     }
//   //   });
//   // };
//   const saveImageAndUserName = async (updateName, image) => {
//     const userName = name + "_" + updateName;
//     // console.log("saveImageAndUserName");
//     const out = await uploadImageName(userName, image);
//     // localStorage.setItem("img", out.files.link);

//     // getUserEmailUserName();
//   };

//   // const enterDetails = () => {
//   //   setUserName(userNameNew);
//   //   // handleCloseImgName();
//   // };

//   const getUserEmailUserName = async () => {
//     setIsLoading(true);
//     const imageData = await getUserImage(name);
//     console.log("localImeg", imageData.files.link);
//     setImg(imageData.files.link);
//     // localStorage.setItem("img", base64);
//     const data = imageData.files.link;
//     setUserName(
//       data
//         .substr(data.lastIndexOf("/") + 1)
//         .split(".")[0]
//         .split("_")[1]
//     );

//     if (imageData.files.link) {
//       setIsLoading(false);
//     }
//   };
//   // const getUserEmailUserName = () => {
//   //   setIsLoading(true);
//   //   getUserImage(name)
//   //     .then((res) => {
//   //       if (res.status === 200) {
//   //         setIsLoading(false);
//   //         const data = res.data && res.data.files.link;
//   //         setImg(data);
//   //         // setUserName(data.lastIndexOf("/") + 1);
//   //         setUserName(
//   //           data
//   //             .substr(data.lastIndexOf("/") + 1)
//   //             .split(".")[0]
//   //             .split("_")[1]
//   //         );
//   //       } else setIsLoading(false);
//   //     })
//   //     .catch((err) => setIsLoading(false));
//   // };

//   // const handleCloseChangePassword = () => {
//   // setOldPassword("");
//   // setNewPassword("");
//   // setConfirmPassword("");
//   // setOpenChangePassword(false);
//   // };

//   const uploadImage = (base64) => {
//     if (img && img.size > 2500000) {
//       handleCloseImageUpload();
//       return setImageShowError("Image should be only max 2MB");
//     }
//     setImageShowError(false);
//     setImg(base64);
//     // console.log("base64.split(", ")[1]", base64);
//     localStorage.setItem("img", base64);
//     saveImageAndUserName(userName, base64.split(",")[1]);
//     handleCloseImageUpload();
//     // getBase64(img).then((base64) => {
//     //   // localStorage["img"] = base64;
//     //   // console.log("base64", base64);
//     //   setImg(base64);
//     //   console.log("base64.split(", ")[1]", base64);
//     //   localStorage.setItem("img", base64);
//     //   saveImageAndUserName(userName, base64.split(",")[1]);
//     //   handleCloseImageUpload();
//     // });
//   };

//   // const uploadImage = () => {
//   //     if (selectedImg.size > 2500000) {
//   //         handleCloseImageUpload();
//   //         return setImageShowError("Image should be only max 2MB");
//   //     }
//   //     setImageShowError(false);
//   //     getBase64(selectedImg).then((base64) => {
//   //         // localStorage["img"] = base64;
//   //         // console.log("base64", base64);
//   //         setImg(base64);
//   //         // console.log("base64.split(", ")[1]", base64);
//   //         localStorage.setItem("img", base64);
//   //         saveImageAndUserName(userName, base64.split(",")[1]);
//   //         handleCloseImageUpload();
//   //     });
//   //     // const uploadImage = (base64) => {
//   //     // if (img.size > 2500000) {
//   //     //   handleCloseImageUpload();
//   //     //   return setImageShowError("Image should be only max 2MB");
//   //     // }
//   //     // setImg(base64);
//   //     // console.log("base64.split(", ")[1]", base64);
//   //     // localStorage.setItem("img", base64);
//   //     // saveImageAndUserName(userName, base64.split(",")[1]);
//   //     // handleCloseImageUpload();
//   //     // getBase64(img).then((base64) => {
//   //     //   // localStorage["img"] = base64;
//   //     //   // console.log("base64", base64);
//   //     //   setImg(base64);
//   //     //   console.log("base64.split(", ")[1]", base64);
//   //     //   localStorage.setItem("img", base64);
//   //     //   saveImageAndUserName(userName, base64.split(",")[1]);
//   //     //   handleCloseImageUpload();
//   //     // });
//   // };

//   return (
//     <div>
//       <Grid container className={classes.main}>
//         <Grid item xs={12} sm={12} md={12} lg={12} className={classes.profile}>
//           <Header />

//           {/* <Box className={classes.rootLogo} style={{ height: "14vh" }}>
//             <IconButton
//               component="span"
//               onClick={() => {
//                 history.goBack();
//               }}
//             >
//               <ChevronLeftIcon />
//             </IconButton>
//             <img src={Logo} alt="logo" />
//           </Box> */}
//           <Box className={classes.editProfile} style={{ height: "100vh" }}>
//             <p>Edit your profile here</p>
//             <img
//               src={img}
//               key={img}
//               name="file"
//               className="img-thumbnail"
//               height={100}
//               width={100}
//               alt="profile"
//             />
//             <input
//               id="selectImage"
//               type="file"
//               onClick={(event) => {
//                 event.target.value = null;
//               }}
//               onChange={handleImg}
//               accept="image/x-png,image/gif,image/jpeg"
//               className={classes.input}
//             />
//             <label
//               className={classes.icon}
//               style={{ marginTop: "-60px" }}
//               htmlFor="selectImage"
//             >
//               {<PhotoCamera />}
//             </label>

//             {showImageError && <span>{showImageError}</span>}
// <CModal
//   maxWidth="xs"
//   open={imageUpload}
//   handleClose={handleCloseImageUpload}
//   bgClick={true}
// >
//   {/* {console.log("selectedImg", selectedImg)} */}
//   <CroppieModal
//     image={selectedImg}
//     uploadImage={uploadImage}
//     uploading={false}
//     closeModal={handleCloseImageUpload}
//     modalTitle={"Change profile picture"}
//     error={showImageError}
//     setImage={setCroppedImage}
//   />
//   <Grid container>
//     <Grid item xs={6} align={"left"}>
//       <div className={classes.cancelBtn}>
//         <Button variant="outlined" onClick={handleCloseImageUpload}>
//           Cancel
//         </Button>
//       </div>
//     </Grid>
//     {/* {console.log("croppedImage", croppedImage)} */}

//     <Grid item xs={6} align={"right"}>
//       <div className={classes.uploadBtn}>
//         <Button
//           variant="outlined"
//           disabled={!selectedImg && !showImageError}
//           onClick={() => uploadImage(croppedImage)}
//         >
//           Upload
//         </Button>
//       </div>
//     </Grid>
//   </Grid>
// </CModal>
//             <Box className={classes.userProfile}>
//               <div style={{ paddingLeft: 15, paddingTop: "38px" }}>
//                 <Typography
//                   className={classes.userName}
//                   gutterBottom
//                   variant="h5"
//                   component="div"
//                 >
//                   {userName}
//                 </Typography>
//                 <label>
//                   <IconButton
//                     color="primary"
//                     aria-label="update name"
//                     component="span"
//                     onClick={handleOpenName}
//                   >
//                     <BorderColorOutlinedIcon color="primary" />
//                   </IconButton>
//                 </label>
//               </div>
//             </Box>
//             <Box
//               style={{
//                 borderRadius: "12px",
//                 backgroundColor: "white",
//                 marginTop: "30px",
//               }}
//             >
//               <Button
//                 onClick={() => {
//                   history.goBack();
//                 }}
//               >
//                 Done
//               </Button>
//             </Box>
//           </Box>
//           <Modal
//             open={openName}
//             onClose={handleCloseName}
//             aria-labelledby="modal-modal-title"
//             aria-describedby="modal-modal-description"
//           >
//             <Box
//               className={classes.modal}
//               style={{ backgroundColor: "#060543" }}
//             >
//               <Typography
//                 id="modal-modal-title"
//                 variant="h6"
//                 component="h2"
//                 className={classes.modalHeader}
//               >
//                 Enter new name
//               </Typography>
//               <TextField
//                 fullWidth
//                 className={classes.modalBody}
//                 required
//                 value={userNameNew}
//                 onChange={(e) => {
//                   setUserNameNew(e.target.value);
//                 }}
//               />
//               <Box className={classes.modalFooter}>
//                 <Button
//                   variant="text"
//                   size="medium"
//                   onClick={handleCloseName}
//                   style={{
//                     backgroundColor: "red",
//                     margin: "13px",
//                     color: "white",
//                   }}
//                 >
//                   cancel
//                 </Button>
//                 <Button
//                   variant="text"
//                   size="medium"
//                   onClick={changeUserName}
//                   disabled={!userNameNew}
//                   style={{
//                     backgroundColor: "#060543",
//                     margin: "13px",
//                     color: "white",
//                   }}
//                 >
//                   confirm
//                 </Button>
//               </Box>
//             </Box>
//           </Modal>
//         </Grid>
//       </Grid>
//     </div>
//   );
// };

// export default EditProfile;
